import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import CgpaTable from './CgpaTable';
import './commonDat.css';
import student from '../../JsonData/student.json';
import courses from '../../JsonData/Courses.json';
// import LoadingBar from '../components/LoadingBar';
const mapStateToProps = (state) => ({
  loginDetails: state.loginDetails,
  student: state.student,
  branches: state.branches,
  webinar_data: state.webinar_data,
  jobPost: state.jobPost,
  loadingBar: state.loadingBar,
  notif: state.notif,
  applications: state.applications,
  notifications: state.notifications
});
const languages = [
  {
    value: 'English'
  },
  {
    value: 'Hindi'
  },
  {
    value: 'Punjabi'
  },
  {
    value: 'French'
  }
];

function AcademicDat(props) {
  const userDat = props.student.student.data;

  // console.log(userDat);
  if (userDat) {
    function mbaBranch(i, j) {
      let n = i * 1;
      const sbranch = [];
      while (n > 0) {
        const b = n % 10;
        // console.log(b)
        sbranch.push(props.branches.branches[j].list[b]);
        n = Math.floor(n / 10);
      }
      return sbranch.join(',');
    }
    return (
      <Box
        sx={{
          p: 3
        }}
      >
        <Box
          sx={{
            mb: 3
          }}
        >
          <Typography
            sx={{
              mb: 3
            }}
            variant="h3"
          >
            College Transcript
          </Typography>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
            sx={{
              mb: 4
            }}
          >
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                InputProps={{
                  readOnly: true
                }}
                fullWidth="true"
                defaultValue={courses.courses[userDat.course]}
                label="Course"
              />
            </Grid>
            {
            userDat.course === 4
            || userDat.course === 5
            || userDat.course === 6
            || userDat.course === 7
            || userDat.course === 8
              ? (
                <></>
              )
              : userDat.course === 3 ? (
                <Grid className="multiLineText" item sm={6} md={5}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth="true"
                    defaultValue={
                    mbaBranch(userDat.branch, userDat.course)
                  }
                    label="Branch"
                  />
                </Grid>
              ) : (
                <Grid className="multiLineText" item sm={6} md={5}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth="true"
                    defaultValue={
                    props.branches.branches[userDat.course].list[userDat.branch]
                  }
                    label="Branch"
                  />
                </Grid>
              )
          }
            {/* <Grid className="multiLineText" item sm={6} md={5}>
                             <TextField InputProps={{
                                readOnly: true,
                            }} fullWidth="true" defaultValue={userDat.branch} label="Branch">
                            </TextField>
                        </Grid> */}
            <Grid className="multiLineText" item sm={6} md={2}>
              <TextField
                InputProps={{
                  readOnly: true
                }}
                fullWidth="true"
                defaultValue={userDat.gradYear}
                label="Year of Graduation"
              />
            </Grid>

            <Grid className="multiLineText" item sm={6} md={2}>
              <TextField
                InputProps={{
                  readOnly: true
                }}
                fullWidth="true"
                defaultValue={userDat.aggregateCgpa}
                label="Aggregate CGPA"
              />
            </Grid>

            {JSON.stringify(userDat.backlogSubjects) === '[]' ? (
              <Grid className="multiLineText" item sm={12} md={12}>
                <TextField
                  InputProps={{
                    readOnly: true
                  }}
                  multiline
                  rowsMax={3}
                  fullWidth="true"
                  defaultValue="None"
                  label="Backlog Subjects"
                />
              </Grid>
            ) : (
              <Grid className="multiLineText" item sm={12} md={12}>
                <TextField
                  InputProps={{
                    readOnly: true
                  }}
                  multiline
                  rowsMax={3}
                  fullWidth="true"
                  defaultValue={userDat.backlogSubjects}
                  label="Backlog Subjects"
                />
              </Grid>
            )}

            {/* <Grid className="multiLineText" item sm={12} md={12}>
              <TextField
                InputProps={{
                  readOnly: true
                }}
                multiline
                rowsMax={10}
                fullWidth="true"
                defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                label="Reasons for Backlog"
              ></TextField>
            </Grid> */}
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                InputProps={{
                  readOnly: true
                }}
                fullWidth="true"
                defaultValue={
                  userDat.cgpa.sem1 === null ? 'NA' : userDat.cgpa.sem1
                }
                label="Semester 1 CGPA"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                InputProps={{
                  readOnly: true
                }}
                fullWidth="true"
                defaultValue={
                  userDat.cgpa.sem2 === null ? 'NA' : userDat.cgpa.sem2
                }
                label="Semester 2 CGPA"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                InputProps={{
                  readOnly: true
                }}
                fullWidth="true"
                defaultValue={
                  userDat.cgpa.sem3 === null ? 'NA' : userDat.cgpa.sem3
                }
                label="Semester 3 CGPA"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                InputProps={{
                  readOnly: true
                }}
                fullWidth="true"
                defaultValue={
                  userDat.cgpa.sem4 === null ? 'NA' : userDat.cgpa.sem4
                }
                label="Semester 4 CGPA"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                InputProps={{
                  readOnly: true
                }}
                fullWidth="true"
                defaultValue={
                  userDat.cgpa.sem5 === null ? 'NA' : userDat.cgpa.sem5
                }
                label="Semester 5 CGPA"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                InputProps={{
                  readOnly: true
                }}
                fullWidth="true"
                defaultValue={
                  userDat.cgpa.sem6 === null ? 'NA' : userDat.cgpa.sem6
                }
                label="Semester 6 CGPA"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                InputProps={{
                  readOnly: true
                }}
                fullWidth="true"
                defaultValue={
                  userDat.cgpa.sem7 === null ? 'NA' : userDat.cgpa.sem7
                }
                label="Semester 7 CGPA"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                InputProps={{
                  readOnly: true
                }}
                fullWidth="true"
                defaultValue={
                  userDat.cgpa.sem8 === null ? 'NA' : userDat.cgpa.sem8
                }
                label="Semester 8 CGPA"
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            mb: 3
          }}
        >
          <Typography
            sx={{
              mb: 3
            }}
            variant="h3"
          >
            Entrance Exam
          </Typography>
          {userDat.entranceExamInfo.map((exam) => (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid className="multiLineText" item sm={6} md={5}>
                <TextField
                  InputProps={{
                    readOnly: true
                  }}
                  multiline
                  rowsMax={3}
                  fullWidth="true"
                  defaultValue={exam.exam}
                  label="Entrance Exam"
                />
              </Grid>
              <Grid className="multiLineText" item sm={6} md={3}>
                <TextField
                  InputProps={{
                    readOnly: true
                  }}
                  fullWidth="true"
                  defaultValue={exam.rank}
                  label="Rank"
                />
              </Grid>
              <Grid className="multiLineText" item sm={6} md={4}>
                <TextField
                  InputProps={{
                    readOnly: true
                  }}
                  fullWidth="true"
                  defaultValue={exam.category}
                  label="Category"
                />
              </Grid>

            </Grid>
          ))}
        </Box>

        {(userDat.course === 1 || userDat.course === 2 || userDat.course === 3)
          ? (
            <Box
              sx={{
                mb: 3
              }}
            >
              <Typography
                sx={{
                  mb: 3
                }}
                variant="h3"
              >
                Under-Graduate
              </Typography>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
              >
                <Grid className="multiLineText" item sm={12} md={9}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    multiline
                    rowsMax={3}
                    fullWidth="true"
                    defaultValue={userDat.undergraduate.name}
                    label="Name"
                  />
                </Grid>
                <Grid className="multiLineText" item sm={6} md={3}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth="true"
                    defaultValue={userDat.undergraduate.percentage}
                    label="Percentage"
                  />
                </Grid>
                <Grid className="multiLineText" item sm={6} md={3}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth="true"
                    defaultValue={userDat.undergraduate.passYear}
                    label="Pass Year"
                  />
                </Grid>

                <Grid className="multiLineText" item sm={6} md={9}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth="true"
                    defaultValue={userDat.undergraduate.course}
                    label="Course"
                  />
                </Grid>

              </Grid>
            </Box>
          ) : <></>}

        <Box
          sx={{
            mb: 3
          }}
        >
          <Typography
            sx={{
              mb: 3
            }}
            variant="h3"
          >
            XIIth Standard
          </Typography>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            <Grid className="multiLineText" item sm={6} md={4}>
              <TextField
                InputProps={{
                  readOnly: true
                  // }} multiline rowsMax={3} fullWidth="true" defaultValue="Sample" label="School Name">
                }}
                multiline
                rowsMax={3}
                fullWidth="true"
                defaultValue={userDat.twelfthClass.name}
                label="School Name"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                InputProps={{
                  readOnly: true
                  // }} fullWidth="true" defaultValue="Sample" label="Board">
                }}
                fullWidth="true"
                defaultValue={userDat.twelfthClass.board}
                label="Board"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={2}>
              <TextField
                InputProps={{
                  readOnly: true
                  // }} fullWidth="true" defaultValue="90" label="CGPA/Percentage">
                }}
                fullWidth="true"
                defaultValue={userDat.twelfthClass.percentage}
                label="CGPA/Percentage"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                InputProps={{
                  readOnly: true
                  // }} fullWidth="true" defaultValue="2000" label="Year of Passing">
                }}
                fullWidth="true"
                defaultValue={userDat.twelfthClass.passYear}
                label="Year of Passing"
              />
            </Grid>

            {/* <Grid className="multiLineText" item sm={12} md={12}>
                             <TextField InputProps={{
                                readOnly: true,
                            }} multiline fullWidth="true" defaultValue={JSON.stringify(userDat.school["12"].subjects).replace(/['"]+/g, ' ').replace(/\[/g, '').replace(/]/g, '')} label="Subjects">
                            </TextField>
                        </Grid> */}
            <Grid className="multiLineText" item sm={12} md={12}>
              <TextField
                InputProps={{
                  readOnly: true
                  // }} multiline fullWidth="true" defaultValue="English, Hindi, Maths" label="Subjects">
                }}
                multiline
                fullWidth="true"
                defaultValue={JSON.stringify(userDat.twelfthClass.subjects)
                  .replace(/['"]+/g, ' ')
                  .replace(/\[/g, '')
                  .replace(/]/g, '')}
                label="Subjects"
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            mb: 3
          }}
        >
          <Typography
            sx={{
              mb: 3
            }}
            variant="h3"
          >
            Xth Standard
          </Typography>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            <Grid className="multiLineText" item sm={6} md={4}>
              <TextField
                InputProps={{
                  readOnly: true
                  // }} multiline rowsMax={3} fullWidth="true" defaultValue="Sample" label="School Name">
                }}
                multiline
                rowsMax={3}
                fullWidth="true"
                defaultValue={userDat.tenthClass.name}
                label="School Name"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                InputProps={{
                  readOnly: true
                  // }} fullWidth="true" defaultValue="Sample" label="Board">
                }}
                fullWidth="true"
                defaultValue={userDat.tenthClass.board}
                label="Board"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={2}>
              <TextField
                InputProps={{
                  readOnly: true
                  // }} fullWidth="true" defaultValue="90" label="CGPA/Percentage">
                }}
                fullWidth="true"
                defaultValue={userDat.tenthClass.percentage}
                label="CGPA/Percentage"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                InputProps={{
                  readOnly: true
                  // }} fullWidth="true" defaultValue="2000" label="Year of Passing">
                }}
                fullWidth="true"
                defaultValue={userDat.tenthClass.passYear}
                label="Year of Passing"
              />
            </Grid>

            {/* <Grid className="multiLineText" item sm={12} md={12}>
                             <TextField InputProps={{
                                readOnly: true,
                            }} fullWidth="true" defaultValue={JSON.stringify(userDat.school["10"].subjects).replace(/['"]+/g, ' ').replace(/\[/g, '').replace(/]/g, '')} label="Subjects">
                            </TextField>
                        </Grid> */}
            <Grid className="multiLineText" item sm={12} md={12}>
              <TextField
                InputProps={{
                  readOnly: true
                  // }} fullWidth="true" defaultValue="English, Hindi, Maths" label="Subjects">
                }}
                fullWidth="true"
                defaultValue={JSON.stringify(userDat.tenthClass.subjects)
                  .replace(/['"]+/g, ' ')
                  .replace(/\[/g, '')
                  .replace(/]/g, '')}
                label="Subjects"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
  return <></>;
}
export default connect(mapStateToProps)(AcademicDat);
