// import JOBPOST from '../JsonData/jobPost.json';
import * as ActionTypes from './ActionTypes';

export const Applications = (state = {
    isLoading:false,
    errmess:null,
    dat:{},
    singleDat:{}
}, action) => {
    switch (action.type) {
        case ActionTypes.GET_APPPLICATIONS:
            return{...state, dat:action.payload}

        case ActionTypes.GET_SINGLEAPPPLICATION:
            return{...state, singleDat:action.payload}

        case ActionTypes.APPLICATION_LOADING:
            return{...state, isLoading:action.payload}

        case ActionTypes.APPLICATION_FAILED:
            return{...state,  errmess:action.payload}

        case ActionTypes.RESET_SINGLEDAT:
            return{...state,  singleDat:{}}

        default:
          return state;
      }
};