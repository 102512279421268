import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import { LoginUser, fetchStudent } from '../redux/ActionCreators';
import { connect } from 'react-redux';
import LoadingBar from '../components/LoadingBar';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/styles';
import './supportBtn.css';

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    student: state.student,
    branches: state.branches,
    webinar_data: state.webinar_data,
    jobPost: state.jobPost,
    loadingBar: state.loadingBar,
    notif: state.notif,
    applications: state.applications,
    notifications: state.notifications
  };
};

const mapDispatchToProps = (dispatch) => ({
  LoginUser: (name, password) => dispatch(LoginUser(name, password))
  // fetchStudent: () => dispatch(fetchStudent())
});

const useStyles = makeStyles(theme => ({
  supportBtn : {

  }
}))

const Login = (props) => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   props.fetchStudent();
  // }, [])

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  }

  const onChangeName = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const onChangePassword = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.LoginUser(name, password);
  };

  // console.log(props);

  return (
    <>
      <Helmet>
        <title>Login | DTU RM</title>
      </Helmet>
      {/* <Box sx={{
          p:4
        }}>
          <TextField value={name} onChange={onChangeName}></TextField>
          <TextField value={password} onChange={onChangePassword}></TextField>
          <Button onClick={handleSubmit}>Submit</Button>
        </Box>
        <Box>
          <Typography>{name}</Typography>
          <Typography>{password}</Typography>
        </Box> */}
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <img
            style={{
              height: '8rem',
              width: '8rem'
            }}
            src="https://upload.wikimedia.org/wikipedia/en/thumb/b/b5/DTU%2C_Delhi_official_logo.png/200px-DTU%2C_Delhi_official_logo.png"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 2,
            mb: 3
          }}
        >
          <Typography variant="h2">DTU RESUME MANAGER</Typography>
        </Box>
        <Container maxWidth="sm">
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 1 }}>
              <Typography color="textPrimary" variant="h2">
                Sign in
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={6}></Grid>
            </Grid>
            <TextField
              fullWidth
              label="Roll No"
              margin="normal"
              onChange={onChangeName}
              value={name}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Password"
              margin="normal"
              onChange={onChangePassword}
              type={showPassword ? "text":"password"}
              value={password}
              variant="outlined"
              InputProps={{
                endAdornment:(
                <InputAdornment position="end" >
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={handleClickShowPassword}
                  // onMouseDown={this.handleMouseDownPassword}
                >
                  {/* <VisibilityOffIcon /> */}
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
                </InputAdornment>
                )
              }}
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign in now
              </Button>
            </Box>
          </form>
          <RouterLink to="/forgotPass">
          <Typography>Forgot Your Password? Click Here</Typography>
          </RouterLink>
          
        </Container>
        <Box className="supportBtn">
        <Typography
          align="center"
          gutterBottom
          variant="h4"
        >
          Need help?
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 1
          }}
        >
          <Button
            color="primary"
            component="a"
            href="mailto:support-rm@dtu.ac.in"
            variant="contained"
          >
            Contact Team
          </Button>
        </Box>
      </Box>
      </Box>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
