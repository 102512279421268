import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import parse from 'html-react-parser';
import { useNavigate } from "react-router-dom";

const mapStateToProps = (state) => {
    return {
      loginDetails: state.loginDetails,
      student: state.student,
      branches: state.branches,
      webinar_data: state.webinar_data,
      jobPost: state.jobPost,
      loadingBar: state.loadingBar,
      notif: state.notif,
      applications: state.applications,
      notifications: state.notifications
    };
  };

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      marginBottom: 12
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)'
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold'
    },
    pos: {
      marginBottom: 12
    }
  });
  

function NotificationCard(props) {
    const classes = useStyles();

    const navigate = useNavigate();

    // const history = useHistory();

    const notifConnect = (index) => {
      if(props.notifications.dat.data[index].jobPost)
      {
        navigate(`/app/jobs/${props.notifications.dat.data[index].jobPost}`);
      }
    }

    return (
        props.notifications.dat.data ? <Grid container justify="center" alignItems="center" spacing={2}>
        {props.notifications.dat.data.map((notification, index) => {
            return (
              <Grid item xs={12} sm={6}>
                <Box onClick={() => notifConnect(index)} sx={{
                  cursor:'pointer'
                }}>
                  <Card className={classes.root}>
                    {/* <CardHeader
                      avatar={
                      <Avatar aria-label="recipe">
                          <FontAwesomeIcon icon={faGoogle} />
                      </Avatar>
                      }
                      // action={
                      // <Typography>Posted on : 31 March 2021</Typography>
                      // }
                      title={jsonJobPost.job_type}
                      subheader={jsonJobPost.company}
                  /> */}
                    <CardContent>
                      {/* <Typography variant="body2" component="p">
                       {`${jsonJobPost.job_description}`}
                      <br />
                      {'"a benevolent smile"'}
                      </Typography> */}
                      <Box sx={{
                          display:'flex',
                          justifyContent:'space-between'
                      }}>
                          <Box
                        sx={{
                          display: 'flex',
                          mb: 2
                        }}
                      >
                        <Avatar aria-label="recipe">
                          {/* <FontAwesomeIcon icon={faGoogle} /> */}
                          {notification.company.name.charAt(0).toUpperCase()}
                        </Avatar>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            pl: 2,
                            justifyContent:'center'
                          }}
                        >
                          {/* <Typography variant="h3" fontWeight={600}>
                            {notification.name}
                          </Typography> */}
                          <Typography sx={{
                            fontSize:'1.3rem',
                            // fontWeight:'600'
                          }}>
                          {notification.company.name}
                              {/* <a style={{
                                  color:'black'
                              }} href={`mailto:${notification.postedBy.email}`}>{notification.postedBy.email}</a> */}
                          </Typography>
                        </Box>
                      </Box>
                      <Box >
                          <Typography fontWeight={700} sx={{
                                color:`${notification.urgency === "critical" ? "red" : notification.urgency === "high" ? "#ca8c1a" : notification.urgency === "medium" ? "#e0e000" : "blue"}`
                            }}>
                              {notification.urgency.charAt(0).toUpperCase() + notification.urgency.slice(1)}
                          </Typography>
                      </Box>
                      </Box>
                      <Box>
                        {/* {console.log(notification)} */}
                          <Typography>
                              {parse(notification.postData)}
                          </Typography>
                            <Typography>
                          <Typography display="inline" fontWeight={600}>Posted on</Typography> :{' '}
                            {new Date(notification.updatedAt).getDate() +
                              '-' +
                              (new Date(notification.updatedAt).getMonth() + 1) +
                              '-' +
                              new Date(notification.updatedAt).getFullYear() +
                              ' ' +
                              (new Date(notification.updatedAt).getHours() >=10 ? new Date(notification.updatedAt).getHours() : '0' + new Date(notification.updatedAt).getHours())
                              +
                              ':' +
                              (new Date(notification.updatedAt).getMinutes() >= 10 ? new Date(notification.updatedAt).getMinutes() : '0' + new Date(notification.updatedAt).getMinutes())}
                          </Typography>
                          <Typography>
                            Contact :&nbsp;
                          {notification.postedBy ? <a href={`mailto:${notification.postedBy.email}`}>{notification.postedBy.email}</a>:<a style={{
                                color:'black'
                            }} href={``}></a>}
                          </Typography>
                      </Box>
                      {/* <Box sx={{
                        display:'flex',
                        justifyContent:'space-between'
                      }}>
                        <Typography>CutOff : Btech: 7.5, Mtech: 8.5</Typography>
                        <Typography>CTC : 15 Lakhs per annum</Typography>
                      </Box> */}
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            );
        })}
      </Grid> : <></>
    )
}

export default connect(mapStateToProps)(NotificationCard);
