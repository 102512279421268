import React, {useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Branches from '../../JsonData/Branches.json';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: 0,
  },
}));

export default function BranchesElig(props) {
  const classes = useStyles();
  const [age, setAge] = React.useState('');

  // const [branches, setBranches] = useState([]);

  const branches = props.course === "BTech" ? Branches.branches[0].list : props.course === "MTech" ? Branches.branches[1].list : Branches.branches[2].list;

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  // console.log(props.branches);

    return (
      <div>
        <FormControl className={classes.formControl}>
          <Select
            value={age}
            onChange={handleChange}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="" disabled>
              Eligibility
            </MenuItem>
            {branches.map((branch,index) => {

              // console.log(branch + index);
              // console.log(props.branches.includes(index));

              if(props.branches.includes(index))
              {
                return (
                  <MenuItem value={`${branch}`}>{branch}</MenuItem>
                )
              }
              else
              {
                return (
                  <MenuItem disabled value={`${branch}`}>{branch}</MenuItem>
                )
              }
              
                // if(branch === "ME" || branch === "ENE")
                // {
                //   return(
                //       
                //   )
                // }
                // else
                // {
                //   return(
                //       <MenuItem value={`${branch}`}>{branch}</MenuItem>
                //   )
                // }
            })}
            {/* <MenuItem disabled value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem> */}
          </Select>
        </FormControl>
      </div>
    );
 
}
