import * as ActionTypes from './ActionTypes';

export const Notifications = (state = {
    isLoading:false,
    errmess:null,
    dat:{},
    notificationPage: 1,
    dashDisplay: true
}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_NOTIFICATIONS:
            return{...state, dat:action.payload}

        case ActionTypes.NOTIFICATIONS_LOADING:
            return{...state, isLoading:action.payload}

        case ActionTypes.NOTIFICATIONS_FAILED:
            return{...state,  errmess:action.payload}

        case ActionTypes.SET_NOTIFICATIONPAGE:
            return{...state,  notificationPage:action.payload}

        case ActionTypes.SET_DASHNOTIFDISP:
            return{...state,  dashDisplay:action.payload}

        default:
          return state;
      }
};