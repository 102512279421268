import { Navigate, Link } from 'react-router-dom';
// import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
// import Account from 'src/pages/Account';
// import CustomerList from 'src/pages/CustomerList';
// import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ForgotPass from 'src/pages/ForgotPass';
import ResetPass from 'src/pages/ResetPass';
// import ProductList from 'src/pages/ProductList';
// import Register from 'src/pages/Register';
// import Settings from 'src/pages/Settings';
// import ProgressPosts from './pages/ProgressPosts';
// import JobsFull from './pages/JobsFull';
// import JobDetails from './pages/JobDetails';
// import LoginForm from './pageComponents/LoginForm/LoginForm';

const routesLogOut = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'forgotPass', element: <ForgotPass /> },
      { path: 'resetPass', element: <ResetPass /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/login" /> }
    ]
  }
];

export default routesLogOut;
