import { Navigate, Link } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Dashboard from 'src/pages/Dashboard';
// import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ProgressPosts from './pages/ProgressPosts';
import JobsFull from './pages/JobsFull';
import JobDetails from './pages/JobDetails';
// import LoginForm from './pageComponents/LoginForm/LoginForm';
import MyApplications from './pages/MyApplications';
import MyApplicationsFull from './pages/MyApplicationsFull'
import MyInfo from './pages/MyInfo';
import MyAccount from './pages/MyAccount'
import MyResume from './pages/MyResume';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'myInfo', element: <MyInfo /> },
      { path: 'myResume', element: <MyResume /> },
      { path: 'myAccount', element: <MyAccount /> },
      { path: 'myApplications', element: <MyApplications /> },
      { path: 'myApplications/:id', element: <MyApplicationsFull /> },
      { path: 'dashboard', element: <Dashboard /> },
      // { path: 'loginform', element: <LoginForm /> },
      { path: 'jobs/:jobPost', element:<JobDetails />},
      { path: 'jobs/:jobPost/progress', element:<ProgressPosts />},
      { path: 'jobs', element: <JobsFull /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Navigate to="/app/dashboard" /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
