import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/core/Autocomplete';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";

const mapStateToProps = (state) => {
    return {
      loginDetails: state.loginDetails,
      student: state.student,
      branches: state.branches,
      webinar_data: state.webinar_data,
      jobPost: state.jobPost,
      loadingBar: state.loadingBar,
      notif: state.notif,
      applications: state.applications,
      notifications: state.notifications
    };
  };

function SearchAutocompleteBox(props) {

    const navigate = useNavigate();

    const onChange = (event,value) => {
        navigate(`/app/jobs/${value._id}`);
    }

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={props.jobPost.jobs.data}
      getOptionLabel={(option) => option.name}
      sx={{ width: '100%' }}
      onChange={onChange}
      renderInput={(params) => <TextField {...params} label="Search for a Job" fullWidth />}
    />
  );
}

export default connect(mapStateToProps)(SearchAutocompleteBox);