export const LOGIN = 'LOGIN';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT='LOGOUT';
export const SET_USERID='SET_USERID';
export const SET_ERRUSERID='SET_ERRUSERID';


export const STUDENT_LOADING='STUDENT_LOADING';
export const UPDATE_STUDENT='UPDATE_STUDENT';
export const STUDENT_FAILED='STUDENT_FAILED';
export const ADD_STUDENT='ADD_STUDENT';


export const JOBS_LOADING='JOBS_LOADING';
export const JOBS_FAILED='JOBS_FAILED';
export const ADD_JOBS='ADD_JOBS';
export const SET_JOBPAGE = 'SET_JOBPAGE';

export const GET_SINGLEJOB='GET_SINGLEJOB';
export const RESET_SINGLEJOB='RESET_SINGLEJOB';

export const WEBINARS_LOADING='WEBINARS_LOADING';
export const ADD_WEBINARS='ADD_WEBINARS';
export const WEBINARS_FAILED='WEBINARS_FAILED';
export const SET_WEBINARPAGE = 'SET_WEBINARPAGE';

export const APPLICATION_LOADING='APPLICATION_LOADING';
export const APPLICATION_FAILED='APPLICATION_FAILED';
export const GET_APPPLICATIONS='GET_APPPLICATIONS';
export const GET_SINGLEAPPPLICATION='GET_SINGLEAPPPLICATION';
export const RESET_SINGLEDAT='RESET_SINGLEDAT';

export const ADD_PROGRESS='ADD_PROGRESS';
export const RESET_PROGRESS='RESET_PROGRESS';
export const SET_PROGRESSJOB = 'SET_PROGRESSJOB';

export const LOADING_BAR='LOADING_BAR';

export const SET_NOTIFMESSAGE='SET_NOTIFMESSAGE';
export const SET_NOTIFSHOW='SET_NOTIFSHOW';

export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING';
export const NOTIFICATIONS_FAILED = 'NOTIFICATIONS_FAILED';
export const SET_NOTIFICATIONPAGE = 'SET_NOTIFICATIONPAGE';
export const SET_DASHNOTIFDISP = 'SET_DASHNOTIFDISP';
