import React from 'react';
import { makeStyles } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    zIndex: 1800,
    '& > * + *': {
      marginTop: 0,
    },
  },
  colorPrimary: {
    backgroundColor: "darkturquoise !important"
  }
}));

export default function LoadingBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <LinearProgress /> */}
      <LinearProgress className={classes.colorPrimary} color="primary" />
    </div>
  );
}
