import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid, Button, Typography } from '@material-ui/core';
import PersonalDat from '../pageComponents/myInfo/PersonalDat';
import AcademicDat from '../pageComponents/myInfo/AcademicDat';
import CoCurricularDat from '../pageComponents/myInfo/CoCurricularDat';
import { LoginUser, fetchStudent } from '../redux/ActionCreators';
import { connect } from 'react-redux';
import LoadingBar from '../components/LoadingBar';

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    student: state.student,
    branches: state.branches,
    webinar_data: state.webinar_data,
    jobPost: state.jobPost,
    loadingBar: state.loadingBar,
    notif: state.notif,
    applications: state.applications,
    notifications: state.notifications
  };
};

function MyInfo(props) {
  const [option, setOption] = useState(1);
  const handleChangeOpt = (dat) => {
    setOption(dat);
  };
  if (
    (props.loadingBar.toShow ||
      props.jobPost.isLoading ||
      props.student.isLoading ||
      props.webinar_data.isLoading) === false
  ) {
    return (
      <>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            <Grid container spacing={3}>
              <Box
                sx={{
                  p: 2,
                  width: '100%'
                }}
              >
                {option === 1 ? (
                  <>
                    <Box
                      sx={{
                        display: 'flex'
                      }}
                    >
                      <Button color="primary" variant="contained">
                        Personal
                      </Button>
                      <Button sx={{ mx: 1 }} onClick={() => handleChangeOpt(2)}>
                        Academic
                      </Button>
                      <Button sx={{ mx: 1 }} onClick={() => handleChangeOpt(3)}>
                        Skills
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}
                    >
                      <PersonalDat />
                    </Box>
                  </>
                ) : option === 2 ? (
                  <>
                    <Box
                      sx={{
                        display: 'flex'
                      }}
                    >
                      <Button onClick={() => handleChangeOpt(1)}>
                        Personal
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ mx: 1 }}
                      >
                        Academic
                      </Button>
                      <Button onClick={() => handleChangeOpt(3)}>Skills</Button>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}
                    >
                      <AcademicDat />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: 'flex'
                      }}
                    >
                      <Button onClick={() => handleChangeOpt(1)}>
                        Personal
                      </Button>

                      <Button onClick={() => handleChangeOpt(2)}>
                        Academic
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ mx: 1 }}
                      >
                        Skills
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}
                    >
                      <CoCurricularDat />
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Container>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            <Grid container spacing={3}>
              <Box
                sx={{
                  p: 2,
                  width: '100%'
                }}
              >
                {option === 1 ? (
                  <>
                    <Box
                      sx={{
                        display: 'flex'
                      }}
                    >
                      <Button color="primary" variant="contained">
                        Personal
                      </Button>
                      <Button sx={{ mx: 1 }} onClick={() => handleChangeOpt(2)}>
                        Academic
                      </Button>
                      <Button sx={{ mx: 1 }} onClick={() => handleChangeOpt(3)}>
                        Skills
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}
                    ></Box>
                  </>
                ) : option === 2 ? (
                  <>
                    <Box
                      sx={{
                        display: 'flex'
                      }}
                    >
                      <Button onClick={() => handleChangeOpt(1)}>
                        Personal
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ mx: 1 }}
                      >
                        Academic
                      </Button>
                      <Button onClick={() => handleChangeOpt(3)}>Skills</Button>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}
                    ></Box>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: 'flex'
                      }}
                    >
                      <Button onClick={() => handleChangeOpt(1)}>
                        Personal
                      </Button>

                      <Button onClick={() => handleChangeOpt(2)}>
                        Academic
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ mx: 1 }}
                      >
                        Skills
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}
                    ></Box>
                  </>
                )}
              </Box>
            </Grid>
          </Container>
        </Box>
      </>
    );
  }
}

export default connect(mapStateToProps)(MyInfo);
