import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, InputLabel, FormControl } from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { fetchApplications, cancelApplication } from '../../redux/ActionCreators';
import './applicationTable.css';

const mapStateToProps = (state) => ({
  loginDetails: state.loginDetails,
  student: state.student,
  branches: state.branches,
  webinar_data: state.webinar_data,
  jobPost: state.jobPost,
  loadingBar: state.loadingBar,
  notif: state.notif,
  applications: state.applications,
  notifications: state.notifications
});

const mapDispatchToProps = (dispatch) => ({
  fetchApplications: () => dispatch(fetchApplications()),
  cancelApplication: (applicationId, jobId) => dispatch(cancelApplication(applicationId, jobId))
  // fetchStudent: () => dispatch(fetchStudent())
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'black',
    color: 'black'
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledButton = withStyles({
  root: {
    background: 'linear-gradient(45deg, #52b788 90%, #52b788 90%)',
    // background: '#FF8E53',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 38,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const StyledButtonWith = withStyles({
  root: {
    background: 'linear-gradient(45deg, #cb1b16 90%, #cb1b16 90%)',
    // background: '#FF8E53',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 38,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

function createData(jobpost, company, status) {
  return { jobpost, company, status };
}

const useStyles = makeStyles({
  table: {
    minWidth: 100
  }
});

function ApplicationTable(props) {
  const classes = useStyles();

  // const [fetched, setFetched] = useState(false);

  const navigate = useNavigate();

  let appCount = false;

  const setAppCount = () => {
    appCount = true;
  };

  const currDate = new Date();

  const [dialogOpen, setDialogOpen] = useState(false);

  const [tempJobId, setTempJobId] = useState();
  const [tempApplicationId, setTempApplicationId] = useState();

  const [appShow, setAppShow] = useState(['Applied', 'Shortlisted']);

  const handleAppShowChange = (event) => {
    const {
      target: { value },
    } = event;
    setAppShow(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(() => {
    if (JSON.stringify(props.applications.dat) === '{}' && props.applications.errmess === null) {
      // console.log("Clickeddddd")
      props.fetchApplications();
    }
  }, []);

  const handleCancel = () => {
    // e.preventDefault();
    props.cancelApplication(tempApplicationId, tempJobId);
  };

  const goToJob = (index) => {
    navigate(`/app/jobs/${props.applications.dat.data[index].post._id}`);
  };

  const handleClose = () => {
    setTempJobId('');
    setTempApplicationId('');
    setDialogOpen(false);
  };

  const openApplyDialog = (appId, jobId) => {
  // e.preventDefault();
    setTempJobId(jobId);
    setTempApplicationId(appId);
    setDialogOpen(true);
  };

  // console.log(rows);
  //   console.log(applications);

  return props.applications.dat.data ? (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography variant="h3">
            Confirm Withdrawal
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>This is a warning message. Are you sure you want to withdraw your application? You will need to re-apply within deadline to be considered.</Typography>
        </DialogContent>
        <DialogActions>
          {/* {console.log(tempApplicationId)} */}
          {/* {console.log(tempJobId)} */}
          <Button onClick={() => { handleCancel(tempApplicationId, tempJobId); }} variant="contained" color="primary">
            Withdraw
          </Button>
        </DialogActions>
      </Dialog>
      <Box className="application-selector">
        <Typography className="appShowdis">Filter</Typography>
        <FormControl className="appShow">
          <InputLabel id="appShowselector">Status</InputLabel>
          <Select
            value={appShow}
            label="Status"
            multiple
            id="appShowselector"
            onChange={handleAppShowChange}
          >
            <MenuItem value="Applied">Applied</MenuItem>
            <MenuItem value="Shortlisted">Shortlisted</MenuItem>
            <MenuItem value="Rejected">Not Shortlisted</MenuItem>
            <MenuItem value="Cancelled">Cancelled</MenuItem>
            <MenuItem value="Selected">Selected</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                sx={{
                  color: 'white'
                }}
              >
                Job Post
              </StyledTableCell>
              <StyledTableCell
                sx={{
                  color: 'white'
                }}
                align="left"
              >
                Company
              </StyledTableCell>
              <StyledTableCell
                sx={{
                  color: 'white'
                }}
                align="left"
              >
                Status
              </StyledTableCell>
              <StyledTableCell
                sx={{
                  color: 'white'
                }}
                align="left"
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.applications.dat.data.map((row, index) => {
              if (appShow.includes(row.status)) {
                setAppCount();
                return (
                  <StyledTableRow key={row.post.name}>
                    <StyledTableCell
                      sx={{
                        cursor: 'pointer'
                      }}
                      onClick={() => goToJob(index)}
                      component="th"
                      scope="row"
                    >
                      {row.post.name}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.company.name}</StyledTableCell>
                    <StyledTableCell style={{ width: '14%' }} align="left">{row.status === 'Rejected' ? 'Not Shortlisted' : row.status}</StyledTableCell>
                    <StyledTableCell
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        gap: 2
                      }}
                      align="right"
                    >
                      <RouterLink to={`/app/myApplications/${row._id}`}>
                        {/* <Button variant="contained">See More</Button> */}
                        <StyledButton>See More / Change Resume</StyledButton>
                      </RouterLink>
                      {row.status === 'Applied' && currDate <= new Date(row.post.applicationClosed) ? <StyledButtonWith onClick={() => { openApplyDialog(row._id, row.post._id); }} variant="contained">Withdraw</StyledButtonWith> : <></> }
                    </StyledTableCell>

                  </StyledTableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {appCount === false ? <Box><Typography>No Applications to Show</Typography></Box> : <></>}
    </>
  ) : (
    <></>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationTable);
