import React, {useState} from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    Link,
    TextField,
    Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import {forgotPassword} from '../redux/ActionCreators';
import './supportBtn.css'


  const mapStateToProps = (state) => {
    return {
      loginDetails: state.loginDetails,
      student: state.student,
      branches: state.branches,
      webinar_data: state.webinar_data,
      jobPost: state.jobPost,
      loadingBar: state.loadingBar,
      notif: state.notif,
      applications: state.applications,
      notifications: state.notifications
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    forgotPassword : (emailId) => dispatch(forgotPassword(emailId))
    // fetchStudent: () => dispatch(fetchStudent())
  });

function ForgotPass(props) {
    const [emailId, setEmailId] = useState("");

    const handleEmail = (e) => {
        e.preventDefault();
        setEmailId(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.forgotPassword(emailId);
    }

    return (
        <>
            <Box
                sx={{
                backgroundColor: 'background.default',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center'
                }}
            >
                <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection:'column',
                    alignItems:'center'
                }}
                >
                    
                <img
                    style={{
                    height: '8rem',
                    width: '8rem'
                    }}
                    src="https://upload.wikimedia.org/wikipedia/en/thumb/b/b5/DTU%2C_Delhi_official_logo.png/200px-DTU%2C_Delhi_official_logo.png"
                />
                </Box>
                <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 2,
                    mb: 3
                }}
                >
                <Typography variant="h2">DTU RESUME MANAGER</Typography>
                </Box>
                <Container maxWidth="sm">
                <form>
                    <Box sx={{ mb: 1 }}>
                    <Typography color="textPrimary" variant="h2">
                        Forgot Password
                    </Typography>
                    </Box>
                    <Grid container spacing={3}>
                    <Grid item xs={12} md={6}></Grid>
                    <Grid item xs={12} md={6}></Grid>
                    </Grid>
                    <TextField
                    fullWidth
                    value={emailId}
                    label="Email"
                    margin="normal"
                    variant="outlined"
                    onChange={handleEmail}
                    />
                    <Box sx={{ py: 2 }}>
                    <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Send Password Reset Link
                    </Button>
                    </Box>
                </form>
                </Container>
                <Box className="supportBtn">
                    <Typography
                    align="center"
                    gutterBottom
                    variant="h4"
                    >
                    Need help?
                    </Typography>
                    {/* <Typography
                    align="center"
                    variant="body2"
                    >
                    Upgrade to PRO version and access 20 more screens
                    </Typography> */}
                    <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        pt: 1
                    }}
                    >
                    <Button
                        color="primary"
                        component="a"
                        href="mailto:support-rm@dtu.ac.in"
                        variant="contained"
                    >
                        Contact Team
                    </Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPass)
