import React, {useState, useEffect} from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    Link,
    TextField,
    Typography
} from '@material-ui/core';
import { useParams, useLocation } from 'react-router';
import { connect } from 'react-redux';
import queryString from 'query-string'
import {checkResetHash, resetPassword} from '../redux/ActionCreators';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import './supportBtn.css'

const mapStateToProps = (state) => {
    return {
      loginDetails: state.loginDetails,
      student: state.student,
      branches: state.branches,
      webinar_data: state.webinar_data,
      jobPost: state.jobPost,
      loadingBar: state.loadingBar,
      notif: state.notif,
      applications: state.applications,
      notifications: state.notifications
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    checkResetHash: (hashId) => dispatch(checkResetHash(hashId)),
    resetPassword: (hashId, emailId, pass) => dispatch(resetPassword(hashId, emailId, pass))
    // fetchStudent: () => dispatch(fetchStudent())
  });

function ResetPass(props) {

  const location = useLocation();
//   console.log(location.search);
  var dat = JSON.stringify(location.search).slice(5);
  dat = dat.slice(0,-1)
//   console.log(dat.slice(5));
  // console.log(dat);

  const [pass, setPass] = useState();

  const handleChangePass = (e) => {
      e.preventDefault();
      setPass(e.target.value);
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  }

  const handleSubmit = (e) => {
      e.preventDefault();
      props.resetPassword(dat,props.loginDetails.userId,pass);
  }

  const errorHelper = "Password must have minimum length of 8 and contain atleast an uppercase character,a lowercase character, a special character and a number";

  const pattern = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
  );


  useEffect(() => {
      if(props.loginDetails.userId === ""  &&  props.loginDetails.errUserId === "")
      {
          props.checkResetHash(dat);
      }
  }, [])

    if(props.loginDetails.userId !== "")
    {
        return (
            <>
                <Box
                    sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                    }}
                >
                    <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection:'column',
                        alignItems:'center'
                    }}
                    >
                        
                    <img
                        style={{
                        height: '8rem',
                        width: '8rem'
                        }}
                        src="https://upload.wikimedia.org/wikipedia/en/thumb/b/b5/DTU%2C_Delhi_official_logo.png/200px-DTU%2C_Delhi_official_logo.png"
                    />
                    </Box>
                    <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 2,
                        mb: 3
                    }}
                    >
                    <Typography variant="h2">DTU RESUME MANAGER</Typography>
                    </Box>
                    <Container maxWidth="sm">
                    <form>
                        <Box sx={{ mb: 1 }}>
                        <Typography color="textPrimary" variant="h2">
                            Reset your Password
                        </Typography>
                        </Box>
                        <Grid container spacing={3}>
                        <Grid item xs={12} md={6}></Grid>
                        <Grid item xs={12} md={6}></Grid>
                        </Grid>
                        <TextField
                        error={(pattern.test(pass)) ? false:true}
                        // error='false'
                        helperText={(pattern.test(pass)) ? '':errorHelper}
                        fullWidth
                        type={showPassword ? "text":"password"}
                        label="New Password"
                        margin="normal"
                        variant="outlined"
                        value={pass}
                        onChange={handleChangePass}
                        InputProps={{
                            endAdornment:(
                            <InputAdornment position="end" >
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={handleClickShowPassword}
                              // onMouseDown={this.handleMouseDownPassword}
                            >
                              {/* <VisibilityOffIcon /> */}
                              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                            </InputAdornment>
                            )
                          }}
                        />
                        <Box sx={{ py: 2 }}>
                        <Button
                            color="primary"
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Reset Your Password
                        </Button>
                        </Box>
                    </form>
                    </Container>
                    <Box className="supportBtn">
                      <Typography
                        align="center"
                        gutterBottom
                        variant="h4"
                      >
                        Need help?
                      </Typography>
                      {/* <Typography
                        align="center"
                        variant="body2"
                      >
                        Upgrade to PRO version and access 20 more screens
                      </Typography> */}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          pt: 1
                        }}
                      >
                        <Button
                          color="primary"
                          component="a"
                          href="mailto:support-rm@dtu.ac.in"
                          variant="contained"
                        >
                          Contact Team
                        </Button>
                      </Box>
                    </Box>
                </Box>
            </>
        )
    }
    else
    {
        return(
            <></>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPass)
