import WEBINAR_DATA from '../JsonData/webinar_data.json';
import * as ActionTypes from './ActionTypes';

export const Webinar_data = (state = {
    isLoading:false,
    errmess:null,
    webinars:{},
    webinarPage:1
}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_WEBINARS:
            return{...state, webinars:action.payload}

        case ActionTypes.WEBINARS_LOADING:
            return{...state, isLoading:action.payload}

        case ActionTypes.WEBINARS_FAILED:
            return{...state,  errmess:action.payload}

        case ActionTypes.SET_WEBINARPAGE:
            return{...state,  webinarPage:action.payload}

        default:
          return state;
      }
};