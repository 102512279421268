import React from 'react'
import ApplicationTable from '../pageComponents/myApplications/ApplicationTable';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

function MyApplications() {
    return (
        <>
        <Box sx={{
            p:4,
            backgroundColor: 'background.default'
        }}>
            <Typography sx={{
                mb:4
            }} variant="h2">My Applications</Typography>
            <ApplicationTable />
        </Box>
            
        </>
    )
}

export default MyApplications
