import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@material-ui/core';
import './commonDat.css';
import { connect } from 'react-redux';
// import LoadingBar from '../components/LoadingBar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Trash2 as DeleteIcon } from 'react-feather';
import { updateStudent } from '../../redux/ActionCreators';

const mapStateToProps = (state) => ({
  loginDetails: state.loginDetails,
  student: state.student,
  branches: state.branches,
  webinar_data: state.webinar_data,
  jobPost: state.jobPost,
  loadingBar: state.loadingBar,
  notif: state.notif,
  applications: state.applications,
  notifications: state.notifications
});

const mapDispatchToProps = (dispatch) => ({
  updateStudent: (
    gaurName,
    motName,
    fatName,
    dob,
    mobile,
    alternateEmail,
    placeOfBirth,
    languages,
    currAddLine1,
    currAddLine2,
    currAddState,
    currAddPost,
    permAddLine1,
    permAddLine2,
    permAddState,
    permAddPost
  ) => dispatch(
    updateStudent(
      gaurName,
      motName,
      fatName,
      dob,
      mobile,
      alternateEmail,
      placeOfBirth,
      languages,
      currAddLine1,
      currAddLine2,
      currAddState,
      currAddPost,
      permAddLine1,
      permAddLine2,
      permAddState,
      permAddPost
    )
  )

  // fetchStudent: () => dispatch(fetchStudent())
});

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'red',
      borderWidth: '2px'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
      borderWidth: '2px'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
        borderWidth: '2px'
      },
      '&:hover fieldset': {
        borderColor: 'red',
        borderWidth: '2px'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
        borderWidth: '2px'
      }
    }
  }
})(TextField);

function PersonalDat(props) {
  const userDat = props.student.student.data;
  // console.log(userDat);
  const [languageSelect, setLanguageSelect] = useState('English');
  const [updateQur, setUpdateQur] = useState();

  const [gaurName, setGaurName] = useState('');
  const [motName, setMotName] = useState('');
  const [fatName, setFatName] = useState('');
  const [alternateEmail, setAlternateEmail] = useState('');
  const [dob, setDob] = useState('');
  const [mobile, setMobile] = useState('');
  const [placeOfBirth, setPlaceOfBirth] = useState('');
  const [languages, setLanguages] = useState([]);
  const [currAddLine1, setCurrAddLine1] = useState('');
  const [currAddLine2, setCurrAddLine2] = useState('');
  const [currAddState, setCurrAddState] = useState('');
  const [currAddPost, setCurrAddPost] = useState('');
  const [permAddLine1, setPermAddLine1] = useState();
  const [permAddLine2, setPermAddLine2] = useState('');
  const [permAddState, setPermAddState] = useState('');
  const [permAddPost, setPermAddPost] = useState('');

  const [tempSkill, setTempSkill] = useState('');
  const [tempLang, setTempLang] = useState('');

  useEffect(() => {
    if (userDat) {
      const currDOB = new Date(userDat.dob);
      const year = currDOB.getFullYear();
      const month = currDOB.getMonth() + 1;
      const day = currDOB.getDate();
      const stringDob = [year, month, day].join('-');
      setGaurName(userDat.guardianName);
      setMotName(userDat.motherName);
      setFatName(userDat.fatherName);
      setDob(stringDob);
      setAlternateEmail(userDat.alternateEmail);
      setMobile(userDat.mobile);
      setPlaceOfBirth(userDat.placeOfBirth);
      setLanguages(userDat.languages);
      setCurrAddLine1(userDat.currentAddress.line1);
      setCurrAddLine2(userDat.currentAddress.line2);
      setCurrAddState(userDat.currentAddress.state);
      setCurrAddPost(userDat.currentAddress.postalCode);
      setPermAddLine1(userDat.permanentAddress.line1);
      setPermAddLine2(userDat.permanentAddress.line2);
      setPermAddState(userDat.permanentAddress.state);
      setPermAddPost(userDat.permanentAddress.postalCode);
    }
  }, [userDat]);

  const handleChangeTempSkill = (e) => {
    e.preventDefault();
    setTempSkill(e.target.value);
  };

  const handleChangeTempLang = (e) => {
    // console.log(tempLang);
    e.preventDefault();
    setTempLang(e.target.value);
  };

  const handleChangeGaurName = (e) => {
    e.preventDefault();
    setGaurName(e.target.value);
  };
  const handleChangeAlternateEmail = (e) => {
    e.preventDefault();
    setAlternateEmail(e.target.value);
  };
  const handleChangeFatName = (e) => {
    e.preventDefault();
    setFatName(e.target.value);
  };
  const handleChangeMotName = (e) => {
    e.preventDefault();
    setMotName(e.target.value);
  };
  const handleChangeDob = (e) => {
    e.preventDefault();
    setDob(e.target.value);
  };

  const handleChangeMobile = (e) => {
    e.preventDefault();
    setMobile(e.target.value);
    // console.log(mobile);
  };

  const handleChangePlace = (e) => {
    e.preventDefault();
    setPlaceOfBirth(e.target.value);
  };

  const handleChangeCA1 = (e) => {
    e.preventDefault();
    setCurrAddLine1(e.target.value);
  };

  const handleChangeCA2 = (e) => {
    e.preventDefault();
    setCurrAddLine2(e.target.value);
  };

  const handleChangeCAS = (e) => {
    e.preventDefault();
    setCurrAddState(e.target.value);
  };

  const handleChangeCAP = (e) => {
    e.preventDefault();
    setCurrAddPost(e.target.value);
  };

  const handleChangePA1 = (e) => {
    e.preventDefault();
    setPermAddLine1(e.target.value);
  };

  const handleChangePA2 = (e) => {
    e.preventDefault();
    setPermAddLine2(e.target.value);
  };

  const handleChangePAS = (e) => {
    e.preventDefault();
    setPermAddState(e.target.value);
  };

  const handleChangePAP = (e) => {
    e.preventDefault();
    setPermAddPost(e.target.value);
  };

  const handleChangeLanguages = (e) => {
    e.preventDefault();
    // console.log(tempLang)
    if (tempLang.length > 0) {
      setLanguages(languages.concat(tempLang));
    }
    // console.log(languages);
    setTempLang('');
  };

  const removeLangauge = (e) => {
    setLanguages(languages.filter((lang) => lang !== e));
  };

  // const handleChangeSkills = (e) => {
  //   e.preventDefault();
  //   if (tempSkill.length > 0) {
  //     setSkills(skills.concat(tempSkill));
  //   }
  //   setTempSkill('');
  // };

  const handleUpdate = (e) => {
    e.preventDefault();
    props.updateStudent(
      gaurName,
      motName,
      fatName,
      dob,
      mobile,
      alternateEmail,
      placeOfBirth,
      languages,
      currAddLine1,
      currAddLine2,
      currAddState,
      currAddPost,
      permAddLine1,
      permAddLine2,
      permAddState,
      permAddPost
    );
  };

  if (userDat) {
    return (
      <>
        {/* {console.log(languages)} */}
        {/* {console.log(mobile)} */}
        {/* {console.log(gaurName)}
            {console.log(mobile)}
            {console.log(skills)}
            {console.log(placeOfBirth)}
            {console.log(languages)}
            {console.log(currAddLine1)}
            {console.log(currAddLine2)}
            {console.log(currAddState)}
            {console.log(currAddPost)}
            {console.log(permAddLine1)}
            {console.log(permAddLine2)}
            {console.log(permAddState)}
            {console.log(permAddPost)} */}
        <Box
          sx={{
            // width:'100vw',
            p: 3
          }}
        >
          {/* <Box sx={{
                        mb:3,
                        float:'right'
                    }}>
                        <RouterLink to="/app">
                            <Button color="primary"
                            variant="contained">
                                Change Your Password
                            </Button>
                        </RouterLink>
                    </Box> */}
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
            sx={{
              mb: 3
            }}
          >
            {userDat.selectedFTE ? (
              <Grid
                className="multiLineText"
                item
                sm={12}
                md={6}
                sx={{
                  mb: 0
                }}
              >
                <Box>
                  <Typography
                    display="inline"
                    sx={{
                      fontSize: '1rem'
                    }}
                  >
                    Selected FTE :
                    {' '}
                  </Typography>
                  <RouterLink to={`/app/jobs/${userDat.selectedFTE._id}`}>
                    <Typography
                      display="inline"
                      sx={{
                        fontSize: '1rem'
                      }}
                      color="darkcyan"
                    >
                      {userDat.selectedFTE.name}
                    </Typography>
                  </RouterLink>
                </Box>
              </Grid>
            ) : <></>}
            {userDat.selectedIntern ? (
              <Grid
                className="multiLineText"
                item
                sm={12}
                md={6}
                sx={{
                  mb: 2
                }}
              >
                <Box>
                  <Typography
                    display="inline"
                    sx={{
                      fontSize: '1rem'
                    }}
                  >
                    Selected Intern :
                    {' '}
                  </Typography>
                  <RouterLink to={`/app/jobs/${userDat.selectedIntern._id}`}>
                    <Typography
                      display="inline"
                      sx={{
                        fontSize: '1rem'
                      }}
                      color="darkcyan"
                    >
                      {userDat.selectedIntern.name}
                    </Typography>
                  </RouterLink>
                </Box>
              </Grid>
            ) : <></>}

            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                className="personalText"
                InputProps={{
                  readOnly: true
                }}
                defaultValue={userDat.firstName}
                label="First Name"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                className="personalText"
                InputProps={{
                  readOnly: true
                }}
                defaultValue={userDat.lastName}
                label="Last Name"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                className="personalText"
                InputProps={{
                  readOnly: true
                }}
                defaultValue={userDat.email}
                label="Email"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                className="personalText"
                InputProps={{
                  readOnly: false
                }}
                value={alternateEmail}
                defaultValue={userDat.alternateEmail}
                onChange={handleChangeAlternateEmail}
                label=" Personal Email"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                className="personalText"
                InputProps={{
                  readOnly: false
                }}
                defaultValue={userDat.mobile}
                value={mobile}
                onChange={handleChangeMobile}
                label="Mobile"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              {/* {console.log((new Date(userDat.dob)).getFullYear() + "-" + ((new Date(userDat.dob)).getMonth() < 10 ? ("0" + (new Date(userDat.dob)).getMonth()) : (new Date(userDat.dob)).getMonth()) + "-" + ((new Date(userDat.dob)).getDate() < 10 ? ("0" + (new Date(userDat.dob)).getDate()) : (new Date(userDat.dob)).getDate()))} */}
              <TextField
                className="personalText"
                InputProps={{
                  readOnly: false
                }}
                value={dob}
                onChange={handleChangeDob}
                className="multiLineText"
                id="date"
                label="Date of Birth ( YY-MM-DD )"
                // type="date"
                defaultValue={
                  `${new Date(userDat.dob).getDate() < 10
                    ? `0${new Date(userDat.dob).getDate()}`
                    : (new Date(userDat.dob).getDate())
                  }-${
                    new Date(userDat.dob).getMonth() + 1 < 10
                      ? `0${new Date(userDat.dob).getMonth() + 1}`
                      : new Date(userDat.dob).getMonth() + 1
                  }-${
                    new Date(userDat.dob).getFullYear()}`
                }
              />
            </Grid>

            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                className="personalText"
                InputProps={{
                  readOnly: true
                }}
                defaultValue={userDat.gender}
                label="Gender"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                className="personalText"
                InputProps={{
                  readOnly: true
                }}
                defaultValue={userDat.rollNo}
                label="Roll No"
              />
            </Grid>
            {/* <Grid className="multiLineText" item sm={6} md={6} >
                            <TextField className="personalText" InputProps={{
                                readOnly: true,
                            }} multiline fullWidth="true" rowsMax={4} defaultValue={JSON.stringify(userDat.currentAddress.line1).replace(/['"]+/g, '') + "\n" + JSON.stringify(userDat.currentAddress.line2).replace(/['"]+/g, '') + "\n" + JSON.stringify(userDat.currentAddress.state).replace(/['"]+/g, '') + " - " + JSON.stringify(userDat.currentAddress.postalCode).replace(/['"]+/g, '')} label="Current Address">
                            </TextField>
                        </Grid> */}
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                className="personalText"
                InputProps={{
                  readOnly: true
                }}
                defaultValue={userDat.citizenship}
                label="Citizenship"
              />
            </Grid>
            {/* <Grid className="multiLineText" item sm={6} md={3} >
                            <TextField className="personalText" InputProps={{
                                readOnly: true,
                            }} defaultValue="Delhi" label="Home Town">
                            </TextField>
                        </Grid>
                        <Grid className="multiLineText" item sm={6} md={3} >
                            <TextField className="personalText" InputProps={{
                                readOnly: true,
                            }} defaultValue="Delhi" label="Home State">
                            </TextField>
                        </Grid> */}
            <Grid className="multiLineText" item sm={6} md={4}>
              <TextField
                className="personalText"
                InputProps={{
                  readOnly: false
                }}
                onChange={handleChangeFatName}
                value={fatName}
                fullWidth="true"
                defaultValue={userDat.fatherName}
                label="Father's Name"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={4}>
              <TextField
                className="personalText"
                InputProps={{
                  readOnly: false
                }}
                onChange={handleChangeMotName}
                value={motName}
                fullWidth="true"
                defaultValue={userDat.motherName}
                label="Mother's Name"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <TextField
                className="personalText"
                InputProps={{
                  readOnly: true
                }}
                defaultValue={userDat.category}
                label="Category"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={4}>
              <TextField
                className="personalText"
                InputProps={{
                  readOnly: false
                }}
                fullWidth="true"
                onChange={handleChangeGaurName}
                value={gaurName}
                defaultValue={userDat.guardianName}
                label="Guardian's Name"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={4}>
              {userDat.debarr ? (
                <CssTextField
                  InputProps={{
                    readOnly: true
                  }}
                  // className={classes.margin}
                  id="custom-css-outlined-input"
                  fullWidth="true"
                  variant="outlined"
                  defaultValue="Yes"
                  label="Debarred"
                />
              ) : (
                <TextField
                  className="personalText"
                  InputProps={{
                    readOnly: true
                  }}
                  fullWidth="true"
                  variant="outlined"
                  defaultValue="No"
                  label="Debarred"
                />
              )}
            </Grid>
            <Grid
              className="multiLineText"
              item
              container
              sm={6}
              md={6}
              spacing={2}
            >
              <Grid className="multiLineText" item sm={6} md={12}>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 'bold'
                  }}
                >
                  Current Address
                </Typography>
              </Grid>
              <Grid className="multiLineText" item sm={6} md={12}>
                <TextField
                  className="personalText"
                  InputProps={{
                    readOnly: false
                  }}
                  fullWidth="true"
                  rowsMax={4}
                  value={currAddLine1}
                  onChange={handleChangeCA1}
                  defaultValue={userDat.currentAddress.line1}
                  label="Line 1"
                />
              </Grid>
              <Grid className="multiLineText" item sm={6} md={12}>
                <TextField
                  className="personalText"
                  InputProps={{
                    readOnly: false
                  }}
                  fullWidth="true"
                  rowsMax={4}
                  value={currAddLine2}
                  onChange={handleChangeCA2}
                  defaultValue={userDat.currentAddress.line2}
                  label="Line 2"
                />
              </Grid>
              <Grid className="multiLineText" item sm={6} md={6}>
                <TextField
                  className="personalText"
                  InputProps={{
                    readOnly: false
                  }}
                  fullWidth="true"
                  rowsMax={4}
                  value={currAddState}
                  onChange={handleChangeCAS}
                  defaultValue={userDat.currentAddress.state}
                  label="State"
                />
              </Grid>
              <Grid className="multiLineText" item sm={6} md={6}>
                <TextField
                  className="personalText"
                  InputProps={{
                    readOnly: false
                  }}
                  fullWidth="true"
                  rowsMax={4}
                  value={currAddPost}
                  onChange={handleChangeCAP}
                  defaultValue={userDat.currentAddress.postalCode}
                  label="Postal Code"
                />
              </Grid>
            </Grid>
            <Grid
              className="multiLineText"
              item
              container
              sm={6}
              md={6}
              spacing={2}
            >
              <Grid className="multiLineText" item sm={6} md={12}>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 'bold'
                  }}
                >
                  Permanent Address
                </Typography>
              </Grid>
              <Grid className="multiLineText" item sm={6} md={12}>
                <TextField
                  className="personalText"
                  InputProps={{
                    readOnly: false
                  }}
                  fullWidth="true"
                  rowsMax={4}
                  value={permAddLine1}
                  onChange={handleChangePA1}
                  defaultValue={userDat.permanentAddress.line1}
                  label="Line 1"
                />
              </Grid>
              <Grid className="multiLineText" item sm={6} md={12}>
                <TextField
                  className="personalText"
                  InputProps={{
                    readOnly: false
                  }}
                  fullWidth="true"
                  rowsMax={4}
                  value={permAddLine2}
                  onChange={handleChangePA2}
                  defaultValue={userDat.permanentAddress.line2}
                  label="Line 2"
                />
              </Grid>
              <Grid className="multiLineText" item sm={6} md={6}>
                <TextField
                  className="personalText"
                  InputProps={{
                    readOnly: false
                  }}
                  fullWidth="true"
                  rowsMax={4}
                  value={permAddState}
                  onChange={handleChangePAS}
                  defaultValue={userDat.permanentAddress.state}
                  label="State"
                />
              </Grid>
              <Grid className="multiLineText" item sm={6} md={6}>
                <TextField
                  className="personalText"
                  InputProps={{
                    readOnly: false
                  }}
                  fullWidth="true"
                  rowsMax={4}
                  value={permAddPost}
                  onChange={handleChangePAP}
                  defaultValue={userDat.permanentAddress.postalCode}
                  label="Postal Code"
                />
              </Grid>
            </Grid>
            {/* <Grid className="multiLineText" item sm={6} md={6} >
                            <TextField className="personalText" InputProps={{
                                readOnly: true,
                            }} multiline fullWidth="true" rowsMax={4} defaultValue={JSON.stringify(userDat.permanentAddress.line1).replace(/['"]+/g, '') + "\n" + JSON.stringify(userDat.permanentAddress.line2).replace(/['"]+/g, '') + "\n" + JSON.stringify(userDat.permanentAddress.state).replace(/['"]+/g, '') + " - " + JSON.stringify(userDat.permanentAddress.postalCode).replace(/['"]+/g, '')} label="Permanent Address">
                            </TextField>
                        </Grid> */}

            {/* <Grid className="multiLineText" item sm={6} md={6} >
                            <TextField className="personalText" InputProps={{
                                readOnly: true,
                            }} multiline fullWidth="true" rowsMax={4} value={languages} defaultValue={userDat.languages} label="Languages">
                            </TextField>
                        </Grid> */}
            <Grid className="multiLineText" item sm={12} md={12}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold'
                }}
              >
                Languages
              </Typography>
            </Grid>
            <Grid className="multiLineText" item sm={6} md={6}>
              <List
                sx={{
                  maxHeight: 120,
                  overflow: 'auto'
                }}
                component="nav"
                aria-label="secondary mailbox folders"
              >
                {languages.map((lang) => (
                  <ListItem
                    sx={{
                      border: '1px solid #cacbcd'
                    }}
                  >
                    <ListItemText primary={`${lang}`} />
                    <DeleteIcon
                      onClick={() => removeLangauge(lang)}
                      style={{
                        cursor: 'pointer'
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid className="multiLineText" item sm={6} md={4}>
              <TextField
                className="personalText"
                InputProps={{
                  readOnly: false
                }}
                multiline
                fullWidth="true"
                onChange={handleChangeTempLang}
                value={tempLang}
                rowsMax={4}
                defaultValue=""
                label="Add into Languages"
              />
            </Grid>
            <Grid className="multiLineText" item sm={6} md={2}>
              <Button onClick={handleChangeLanguages} variant="contained">
                Add
              </Button>
            </Grid>
            {/* <Grid className="multiLineText" item sm={12} md={12}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold'
                }}
              >
                Job Interests
              </Typography>
            </Grid> */}
            {/* <Grid className="multiLineText" item sm={6} md={6}>
              <List
                sx={{
                  maxHeight: 120,
                  overflow: 'auto'
                }}
                component="nav"
                aria-label="secondary mailbox folders"
              >
                {skills.map((skill) => {
                  return (
                    <ListItem
                      sx={{
                        border: '1px solid #cacbcd'
                      }}
                    >
                      <ListItemText primary={`${skill}`} />
                    </ListItem>
                  );
                })}
              </List>
            </Grid> */}
            {/* <Grid className="multiLineText" item sm={6} md={4}>
              <TextField className="personalText"
                InputProps={{
                  readOnly: false
                }}
                multiline
                fullWidth="true"
                onChange={handleChangeTempSkill}
                value={tempSkill}
                rowsMax={4}
                defaultValue=""
                label="Add into Skills"
              ></TextField>
            </Grid>
            <Grid className="multiLineText" item sm={6} md={2}>
              <Button onClick={handleChangeSkills} variant="contained">
                Add
              </Button>
            </Grid> */}
            {/* <Grid className="multiLineText" item sm={6} md={3} >
                        <TextField className="personalText"
                            // id="standard-select-currency"
                            select
                            label="Languages"
                            defaultValue="English"
                            value="English"
                            fullWidth="true"
                            >
                            {userDat.languages.map((option) => (
                                <MenuItem value={option}>
                                {option}
                                </MenuItem>
                            ))}
                            </TextField>
                        </Grid> */}

            {/* <Grid className="multiLineText" item sm={6} md={4} >
                            <TextField className="personalText" InputProps={{
                                readOnly: true,
                            }} type="number" fullWidth="true" defaultValue="172" label="Height (in cms)">
                            </TextField>
                        </Grid>
                        <Grid className="multiLineText" item sm={6} md={4} >
                            <TextField className="personalText" InputProps={{
                                readOnly: true,
                            }} type="number" fullWidth="true" defaultValue="57" label="Weight (in kgs)">
                            </TextField>
                        </Grid> */}

            {/* <Grid className="multiLineText" item sm={6} md={6} >
                            <TextField className="personalText" InputProps={{
                                readOnly: true,
                            }} multiline fullWidth="true" rowsMax={4} defaultValue="Software Development, Business Manager, iOS Development, Research, Data Scientist" label="Job Interests">
                            </TextField>
                        </Grid>
                        <Grid className="multiLineText" item sm={6} md={4} >
                            <TextField className="personalText" InputProps={{
                                readOnly: true,
                            }} multiline fullWidth="true" rowsMax={4} value={tempSkill} defaultValue="" label="Add into Job Interests">
                            </TextField>
                        </Grid>
                        <Grid className="multiLineText" item sm={6} md={2} >
                            <Button variant="contained">Add</Button>
                        </Grid> */}
          </Grid>
          <Button
            variant="contained"
            onClick={handleUpdate}
            sx={{
              ml: '41%'
            }}
          >
            Update
          </Button>
        </Box>
      </>
    );
  }
  return <></>;
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDat);
