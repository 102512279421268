import STUDENT from '../JsonData/student.json';
import * as ActionTypes from './ActionTypes';

export const Student = (state = {
    isLoading:false,
    errmess:null,
    student:{},
}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_STUDENT:
            return{...state, student:action.payload}

        case ActionTypes.STUDENT_LOADING:
            return{...state, isLoading:action.payload}

        case ActionTypes.STUDENT_FAILED:
            return{...state,  errmess:action.payload}

        case ActionTypes.UPDATE_STUDENT:
            return{...state,  isLoading:action.payload}

        default:
          return state;
      }
};