import React, {useState,useEffect,useRef} from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import JobPostCard from '../pageComponents/jobs/JobPostCard';
import Pagination from '@material-ui/core/Pagination';
import { useParams, useLocation } from 'react-router';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { fetchJobs } from '../redux/ActionCreators';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useNavigate } from "react-router-dom";
import SearchAutocompleteBox from '../pageComponents/jobs/SearchAutocompleteBox';


const mapStateToProps = (state) => {
    return {
      loginDetails: state.loginDetails,
      student: state.student,
      branches: state.branches,
      webinar_data: state.webinar_data,
      jobPost: state.jobPost,
      loadingBar: state.loadingBar,
      notif: state.notif,
      applications: state.applications,
      notifications: state.notifications
    };
  };

  const mapDispatchToProps = (dispatch) => ({
    fetchJobs: (page) => dispatch(fetchJobs(page))
  
    // fetchStudent: () => dispatch(fetchStudent())
  });



function JobsFull(props) {
    const location = useLocation();
    // console.log(location.search);
    const dat = queryString.parse(location.search, {parseNumbers: true});
    const [page, setPage] = useState(JSON.stringify(dat) !== '{}' ? dat.page : 1)

    const navigate = useNavigate();

    

    // console.log(dat);
    // console.log(dat.page);

    useEffect(() => {
        if(!props.jobPost.jobs.data || page !== props.jobPost.jobPage)
        {
            props.fetchJobs(page);
        }
    }, [])

    // console.log("datPage" + page);

    const [pageCount, setPageCount] = useState(0);

    const handlePageNext = (e) => {
        e.preventDefault();
        setPage(page+1);
        // navigate(`/app/jobs?page=${page+1}`)
        // props.fetchJobs(page+1);
    }
    
    const handlePagePrev = (e) => {
        e.preventDefault();
        setPage(page-1);
        // navigate(`/app/jobs?page=${page-1}`)
        // props.fetchJobs(page-1);
    }


    const handlePageChange = (event,value) => {
        const element  = document.getElementById("jobsScroll");
        // console.log(element)
        element.scrollTop = 0;
      
        setPage(value);
        // if(value !== page)
        // {
        //     navigate(`/app/jobs?page=${value}`)
        // props.fetchJobs(value);
        // }
    }

    useEffect(() => {
        if(props.jobPost.jobs.data)
        {
            // console.log(props.jobPost.jobs.data.length / 10);
            setPageCount(3);
            // setPageCount(props.jobPost.jobs.data.length % 10 !== 0 ?  Math.floor((props.jobPost.jobs.data.length / 10)) + 1 : Math.floor((props.jobPost.jobs.data.length / 10)))
        }
    }, [props.jobPost.jobs])

    // console.log(pageCount);
    return (
        props.loadingBar.toShow ? <></> : <>
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                pt: 3,
                pb:1
            }}
            >
            <Container maxWidth={false}>
                <Grid
                container
                spacing={3}
                >

                <Box
                sx={{
                    p:2,
                    width:'100%'
                }} 
                >

                    <Box sx={{
                        display:'flex',
                        justifyContent:'center'
                    }}>
                        <SearchAutocompleteBox/>
                    </Box>
                    
                <Box sx={{
                    p:2,
                    width:'100%',
                    display:'flex',
                    flexWrap:'wrap'
                    }}>
                        
                    <JobPostCard count={page} />
                    </Box>
                </Box>
                
                </Grid>
            </Container>
            <Box sx={{
                display:"flex",
                justifyContent:'space-evenly',
                alignItems:'center',
                width:'50%',
                margin:'auto'
            }}>
                {props.jobPost.jobs.data ? <>
                    {/* {page > 1 ? <ArrowBackIcon sx={{
                            cursor:'pointer'
                          }} onClick={handlePagePrev}/> : <ArrowBackIcon sx={{
                            cursor:'pointer'
                          }} color='disabled'/>} */}
                <Pagination page={page} onChange={handlePageChange} count={(Math.floor(props.jobPost.jobs.data.length/10) + (props.jobPost.jobs.data.length%10 == 0 ? 0:1))} color="primary" size="large" siblingCount={1} />
                {/* {props.jobPost.jobs.data.length == 10 ? <ArrowForwardIcon sx={{
                            cursor:'pointer'
                          }} onClick={handlePageNext}/> : <ArrowForwardIcon sx={{
                            cursor:'pointer'
                          }} color='disabled'/>} */}
                </> : <></>}
            </Box>
            </Box>
        </>
    )
}

export default connect(mapStateToProps,mapDispatchToProps)(JobsFull)
