import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  fetchSingleApplication,
  updateApplication,
  cancelApplication
} from '../redux/ActionCreators';

const mapStateToProps = (state) => ({
  loginDetails: state.loginDetails,
  student: state.student,
  branches: state.branches,
  webinar_data: state.webinar_data,
  jobPost: state.jobPost,
  loadingBar: state.loadingBar,
  notif: state.notif,
  applications: state.applications,
  notifications: state.notifications
});

const mapDispatchToProps = (dispatch) => ({
  fetchSingleApplication: (applicationId) => dispatch(fetchSingleApplication(applicationId)),
  cancelApplication: (applicationId, jobId) => dispatch(cancelApplication(applicationId, jobId)),
  updateApplication: (applicationId, jobId, content, resumeNumber) => dispatch(updateApplication(applicationId, jobId, content, resumeNumber))
  // fetchStudent: () => dispatch(fetchStudent())
});

function MyApplicationsFull(props) {
  const slug = useParams();
  const applicationId = slug.id;

  const currDate = new Date();

  const [resumeNumber, setResumeNumber] = useState('');
  const [content, setContent] = useState();

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const openApplyDialog = (e) => {
    setDialogOpen(true);
  };

  const setResumeNum1 = (e) => {
    e.preventDefault();
    if (resumeNumber === 'resume1') {
      setResumeNumber('');
    } else {
      setResumeNumber('resume1');
    }
  };

  const setResumeNum2 = (e) => {
    e.preventDefault();
    if (resumeNumber === 'resume2') {
      setResumeNumber('');
    } else {
      setResumeNumber('resume2');
    }
  };

  const setResumeNum3 = (e) => {
    e.preventDefault();
    if (resumeNumber === 'resume3') {
      setResumeNumber('');
    } else {
      setResumeNumber('resume3');
    }
  };

  const changeContent = (e) => {
    e.preventDefault();
    setContent(e.target.value);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    props.updateApplication(
      props.applications.singleDat.data._id,
      props.applications.singleDat.data.post._id,
      content,
      resumeNumber
    );
  };

  const handleCancel = (e) => {
    e.preventDefault();
    props.cancelApplication(
      props.applications.singleDat.data._id,
      props.applications.singleDat.data.post._id
    );
  };
  // const application = applications[slug.id-1];

  // const [application, setApplicatio]
  const navigate = useNavigate();

  useEffect(() => {
    if (JSON.stringify(props.applications.singleDat) != '{}') {
      setResumeNumber(props.applications.singleDat.data.resume);
      setContent(props.applications.singleDat.data.content);
    }
  }, [props.applications.singleDat]);

  useEffect(() => {
    if (JSON.stringify(props.applications.singleDat) === '{}') {
      props.fetchSingleApplication(applicationId);
    } else if (props.applications.singleDat.data._id != applicationId) {
      props.fetchSingleApplication(applicationId);
    }
  }, [props.applications.singleDat]);

  if (JSON.stringify(props.applications.singleDat) === '{}' || props.loadingBar.toShow || JSON.stringify(props.student.student) === '{}') {
    return (
      <>
        <Box
          sx={{
            p: 4
          }}
        >
          <Button
            sx={{
              mb: 4
            }}
            color="primary"
            variant="contained"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Box>
      </>
    );
  }
  const application = props.applications.singleDat.data;

  // console.log(application);
  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography variant="h3">Confirm Withdrawal</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            This is a warning message. Are you sure you want to withdraw your application? You will need to re-apply within deadline to be considered.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="contained" color="primary">
            Withdraw
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          p: 4
        }}
      >
        <Button
          sx={{
            mb: 4
          }}
          color="primary"
          variant="contained"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>

        <Box
          sx={{
            mb: 3
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}
          >
            <Box>
              <Typography
                variant="h3"
                sx={{
                  mb: 1
                }}
              >
                Job Post:
                {' '}
                {application.post.name}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  mb: 1
                }}
              >
                Company:
                {' '}
                {application.company.name}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  mb: 1
                }}
              >
                Status:
                {' '}
                {application.status}
              </Typography>
              {/* {console.log(currDate)}
                            {console.log(currDate.toLocaleString())} */}

              {application.status === 'Applied'
                && currDate <= new Date(application.post.applicationClosed) ? (
                  <Button onClick={openApplyDialog} variant="contained">
                    Withdraw
                  </Button>
                ) : (
                  <></>
                )}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            mb: 2
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 3
            }}
          >
            <Typography
              sx={{
                mb: 2
              }}
            >
              <Typography variant="h4">
                Submitted On :
                {' '}
                {`${new Date(application.createdAt).getDate()
                }-${
                  new Date(application.createdAt).getMonth() + 1
                }-${
                  new Date(application.createdAt).getFullYear()
                } ${
                  new Date(application.createdAt).getHours() >= 10 ? new Date(application.createdAt).getHours() : `0${new Date(application.createdAt).getHours()}`

                }:${
                  new Date(application.createdAt).getMinutes() >= 10 ? new Date(application.createdAt).getMinutes() : `0${new Date(application.createdAt).getMinutes()}`}`}
                {' '}
              </Typography>
              {' '}
            </Typography>
            <RouterLink to={`/app/jobs/${application.post._id}/progress`}>
              <Button variant="contained">See Progress</Button>
            </RouterLink>
          </Box>
          <Typography
            variant="h4"
            sx={{
              marginBottom: 2
            }}
          >
            Selected Resume
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              flexWrap: 'wrap',
              marginBottom: 4,
              gap: 2
            }}
          >
            {props.student.student.data.resume.resume1 === ''
              && props.student.student.data.resume.resume2 === ''
              && props.student.student.data.resume.resume3 === '' ? (
                <Typography>Please upload any one Resume</Typography>
              ) : (
                <></>
              )}
            {resumeNumber === 'resume1' ? (
              <Chip
                avatar={<Avatar>1</Avatar>}
                label="Resume 1"
                clickable
                  // variant="outlined"
                onClick={setResumeNum1}
                color="primary"
                deleteIcon={<DoneIcon />}
              />
            ) : props.student.student.data.resume.resume1 === '' ? (
              <>
                <Chip
                  avatar={<Avatar>1</Avatar>}
                  label="Resume 1"
                  variant="outlined"
                  clickable
                  color="primary"
                  deleteIcon={<DoneIcon />}
                />
              </>
            ) : (
              <Chip
                avatar={<Avatar>1</Avatar>}
                label="Resume 1"
                variant="outlined"
                clickable
                onClick={setResumeNum1}
                color="primary"
                deleteIcon={<DoneIcon />}
              />
            )}

            {resumeNumber === 'resume2' ? (
              <Chip
                avatar={<Avatar>2</Avatar>}
                label="Resume 2"
                clickable
                  // variant="outlined"
                onClick={setResumeNum2}
                color="primary"
                deleteIcon={<DoneIcon />}
              />
            ) : props.student.student.data.resume.resume2 === '' ? (
              <>
                <Chip
                  avatar={<Avatar>2</Avatar>}
                  label="Resume 2"
                  variant="outlined"
                  clickable
                  color="primary"
                  deleteIcon={<DoneIcon />}
                />
              </>
            ) : (
              <Chip
                avatar={<Avatar>2</Avatar>}
                label="Resume 2"
                variant="outlined"
                clickable
                onClick={setResumeNum2}
                color="primary"
                deleteIcon={<DoneIcon />}
              />
            )}

            {resumeNumber === 'resume3' ? (
              <Chip
                avatar={<Avatar>3</Avatar>}
                label="Resume 3"
                clickable
                onClick={setResumeNum3}
                color="primary"
                deleteIcon={<DoneIcon />}
              />
            ) : props.student.student.data.resume.resume3 === '' ? (
              <>
                <Chip
                  avatar={<Avatar>3</Avatar>}
                  label="Resume 3"
                  variant="outlined"
                  clickable
                  color="primary"
                  deleteIcon={<DoneIcon />}
                />
              </>
            ) : (
              <Chip
                avatar={<Avatar>3</Avatar>}
                label="Resume 3"
                variant="outlined"
                clickable
                onClick={setResumeNum3}
                color="primary"
                deleteIcon={<DoneIcon />}
              />
            )}
          </Box>
          <Typography>
            <Typography
              variant="h4"
              sx={{
                marginBottom: 2
              }}
            >
              Content :
            </Typography>
          </Typography>
          <TextField
            multiline
            rowsMax={10}
            fullWidth="true"
            onChange={changeContent}
            defaultValue={application.content}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {(content === '' && resumeNumber === '')
            || (content === props.applications.singleDat.data.content
              && resumeNumber === props.applications.singleDat.data.resume) ? (
                <Button variant="contained" disabled>
                  Update
                </Button>
            ) : (
              <Button variant="contained" onClick={handleUpdate}>
                Update
              </Button>
            )}
        </Box>

        {/* <Box sx={{
                        display:'flex',
                        flexWrap:'wrap',
                        alignItems:'center'
                    }}>
                        <Typography sx={{
                            mr:2
                        }}>Resume Selected : {application.resume}
                        </Typography>
                        <Button variant="contained">View</Button>

                    </Box> */}
      </Box>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MyApplicationsFull);
