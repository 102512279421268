import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SvgIcon from '@material-ui/core/SvgIcon';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { cloudFrontUrl } from '../baseUrl';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';
import { updateResume } from '../redux/ActionCreators';
import VisibilityIcon from '@material-ui/icons/Visibility';

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    student: state.student,
    branches: state.branches,
    webinar_data: state.webinar_data,
    jobPost: state.jobPost,
    loadingBar: state.loadingBar,
    notif: state.notif,
    applications: state.applications,
    notifications: state.notifications
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateResume: (resumeFile, resumeNumber) =>
    dispatch(updateResume(resumeFile, resumeNumber))

  // fetchStudent: () => dispatch(fetchStudent())
});

function MyResume(props) {
  const [resume1, setResume1] = useState();
  const [resume2, setResume2] = useState();
  const [resume3, setResume3] = useState();

  const [tempResume1, setTempResume1] = useState();
  const [tempResume2, setTempResume2] = useState();
  const [tempResume3, setTempResume3] = useState();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (props.student.student.data) {
      setResume1(props.student.student.data.resume.resume1);
      setResume2(props.student.student.data.resume.resume2);
      setResume3(props.student.student.data.resume.resume3);
      setLoaded(true);
    }
  }, [props.student.student]);

  const submitResume1 = (e) => {
    e.preventDefault();

    props.updateResume(tempResume1, 'resume1');
  };

  const handleResume1 = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const temp = new FormData();
    temp.append('document', file);

    setTempResume1(temp);

    // props.updateProfilePic(profilePic);
  };

  const submitResume2 = (e) => {
    e.preventDefault();

    props.updateResume(tempResume2, 'resume2');
  };

  const handleResume2 = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const temp = new FormData();
    temp.append('document', file);

    setTempResume2(temp);

    // props.updateProfilePic(profilePic);
  };

  const submitResume3 = (e) => {
    e.preventDefault();

    props.updateResume(tempResume3, 'resume3');
  };

  const handleResume3 = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const temp = new FormData();
    temp.append('document', file);

    setTempResume3(temp);

    // props.updateProfilePic(profilePic);
  };

  if ((loaded) && (JSON.stringify(props.student.student.data) != '{}')) {
    return (
      <>
        <Box
          sx={{
            p: 4
          }}
        >
          <Box
            sx={{
              display: 'flex'
            }}
          >
            <Typography
              sx={{
                mb: 4,
                mr: '1rem'
              }}
              variant="h1"
            >
              My Resume
            </Typography>
            {/* {console.log(props.student.student.lockResumes)} */}
            {props.student.student.lockResumes ? (
              <Grid className="multiLineText" item sm={6} md={1}>
                <LockOutlinedIcon fontSize="large" />
              </Grid>
            ) : (
              <Grid className="multiLineText" item sm={6} md={1}>
                <LockOpenOutlinedIcon fontSize="large" />
              </Grid>
            )}
          </Box>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
            sx={{
              mb: 3
            }}
          >
            {props.student.student.lockResumes ? (
              <Grid className="multiLineText" item sm={12} md={12}>
              <Typography variant="h4" sx={{
                mb:2
              }}>The time period for updating the resume is over.</Typography>
            </Grid>
            ) : (
              <></>
            )}
            
            <Grid className="multiLineText" item sm={6} md={1}>
              <Typography>1. </Typography>
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <Button variant="outlined" component="label" color="secondary">
                Select Your Resume 1
                {props.student.student.lockResumes ? (
                  <input
                    onChange={handleResume1}
                    type="file"
                    accept=".doc,.docx,.pdf"
                    hidden
                    disabled
                  />
                ) : (
                  <input
                    onChange={handleResume1}
                    type="file"
                    accept=".doc,.docx,.pdf"
                    hidden
                  />
                )}
              </Button>
            </Grid>
            {tempResume1 ? (
              <Grid className="multiLineText" item sm={6} md={2}>
                <Button
                  onClick={submitResume1}
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Save
                </Button>
              </Grid>
            ) : (
              <></>
            )}
            {props.student.student.data.resume.resume1 ? (
              <Grid className="multiLineText" item sm={6} md={2}>
                <Button
                  variant="contained"
                  target="_blank"
                  href={encodeURI(
                    cloudFrontUrl +
                      props.student.student.data.resume.resume1
                  )}
                  // startIcon={<CloudUploadIcon />}
                >
                  View
                </Button>
              </Grid>
            ) : (
              <></>
            )}

            {tempResume1 ? (
              <Typography>{tempResume1.get('document').name}</Typography>
            ) : (
              <></>
            )}
            {/* <Grid className="multiLineText" item sm={6} md={2}>
                                <SvgIcon
                                    fontSize="small"
                                    color="action"
                                >
                                    <VisibilityIcon/>
                                </SvgIcon>
                            </Grid> */}
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
            sx={{
              mb: 3
            }}
          >
            <Grid className="multiLineText" item sm={6} md={1}>
              <Typography>2. </Typography>
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <Button variant="outlined" component="label" color="secondary">
                Select Your Resume 2
                {props.student.student.lockResumes ? (
                  <input
                    onChange={handleResume2}
                    type="file"
                    accept=".doc,.docx,.pdf"
                    hidden
                    disabled
                  />
                ) : (
                  <input
                    onChange={handleResume2}
                    type="file"
                    accept=".doc,.docx,.pdf"
                    hidden
                  />
                )}
              </Button>
            </Grid>

            {tempResume2 ? (
              <Grid className="multiLineText" item sm={6} md={2}>
                <Button
                  onClick={submitResume2}
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Save
                  {/* {console.log(tempResume2.get('document'))} */}
                </Button>
              </Grid>
            ) : (
              <></>
            )}
            {props.student.student.data.resume.resume2 ? (
              <Grid className="multiLineText" item sm={6} md={2}>
                <Button
                  variant="contained"
                  target="_blank"
                  href={encodeURI(
                    cloudFrontUrl +
                      props.student.student.data.resume.resume2
                  )}
                  // startIcon={<CloudUploadIcon />}
                >
                  View
                </Button>
              </Grid>
            ) : (
              <></>
            )}

            {tempResume2 ? (
              <Typography>{tempResume2.get('document').name}</Typography>
            ) : (
              <></>
            )}

            {/* <Grid className="multiLineText" item sm={6} md={2}>
                                <SvgIcon
                                    fontSize="small"
                                    color="action"
                                >
                                    <VisibilityIcon/>
                                </SvgIcon>
                            </Grid> */}
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
            sx={{
              mb: 3
            }}
          >
            <Grid className="multiLineText" item sm={6} md={1}>
              <Typography>3. </Typography>
            </Grid>
            <Grid className="multiLineText" item sm={6} md={3}>
              <Button variant="outlined" component="label" color="secondary">
                Select Your Resume 3
                {props.student.student.lockResumes ? (
                  <input
                    onChange={handleResume3}
                    type="file"
                    accept=".doc,.docx,.pdf"
                    hidden
                    disabled
                  />
                ) : (
                  <input
                    onChange={handleResume3}
                    type="file"
                    accept=".doc,.docx,.pdf"
                    hidden
                  />
                )}
              </Button>
            </Grid>

            {tempResume3 ? (
              <Grid className="multiLineText" item sm={6} md={2}>
                <Button
                  onClick={submitResume3}
                  // disabled
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Save
                </Button>
              </Grid>
            ) : (
              <></>
            )}

            {props.student.student.data.resume.resume3 ? (
              <Grid className="multiLineText" item sm={6} md={2}>
                <Button
                  variant="contained"
                  target="_blank"
                  href={encodeURI(
                    cloudFrontUrl +
                      props.student.student.data.resume.resume3
                  )}
                  // startIcon={<CloudUploadIcon />}
                >
                  View
                </Button>
              </Grid>
            ) : (
              <></>
            )}

            {tempResume3 ? (
              <Typography>{tempResume3.get('document').name}</Typography>
            ) : (
              <></>
            )}

            {/* <Grid className="multiLineText" item sm={6} md={2}>
                                <SvgIcon
                                    fontSize="large"
                                    color="action"
                                >
                                    <VisibilityIcon/>
                                </SvgIcon>
                            </Grid> */}
          </Grid>
          <Typography>Note - Size of your resume file should not be more than 2 MB</Typography>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyResume);
