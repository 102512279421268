import React, { useState, useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
// import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import { connect } from 'react-redux';
import routesLogOut from 'src/routesLogOut';
import Snackbar from '@material-ui/core/Snackbar';
import LoadingBar from './components/LoadingBar';
import {
  fetchStudent,
  fetchWebinars,
  ChangeNotifShow,
  ChangeNotifMessage,
  fetchNotifications
} from './redux/ActionCreators';

const mapStateToProps = (state) => ({
  loginDetails: state.loginDetails,
  student: state.student,
  branches: state.branches,
  webinar_data: state.webinar_data,
  jobPost: state.jobPost,
  loadingBar: state.loadingBar,
  notif: state.notif,
  applications: state.applications,
  notifications: state.notifications
});

const mapDispatchToProps = (dispatch) => ({
  fetchStudent: () => dispatch(fetchStudent()),
  fetchWebinars: (page) => dispatch(fetchWebinars(page)),
  ChangeNotifShow: (dat) => dispatch(ChangeNotifShow(dat)),
  ChangeNotifMessage: (dat) => dispatch(ChangeNotifMessage(dat)),
  fetchNotifications: (notifPage) => dispatch(fetchNotifications(notifPage))

  // fetchStudent: () => dispatch(fetchStudent())
});

const MainApp = (props) => {
  // console.log(props);
  const routingLogIn = useRoutes(routes);
  const routingLogOut = useRoutes(routesLogOut);

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  // console.log(creds);
  // console.log(props);

  const vertical = 'top';
  const horizontal = 'center';

  useEffect(() => {
    if (props.notif.toShow) {
      setTimeout(() => {
        props.ChangeNotifMessage('');
        props.ChangeNotifShow(false);
      }, 3000);
    }
  }, [props.notif.toShow]);

  useEffect(() => {
    if (creds) {
      props.fetchStudent();
      props.fetchWebinars(1);
      props.fetchNotifications(1);
    }
  }, [creds]);

  const isLoading = props.loadingBar.toShow
    || props.jobPost.isLoading
    || props.student.isLoading
    || props.webinar_data.isLoading;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {isLoading && <LoadingBar />}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={props.notif.toShow}
        // onClose={handleClose}
        message={props.notif.message}
        // message="Successful"
        key={vertical + horizontal}
      />
      {/* <Alert severity="success">
            This is a success message!
          </Alert> */}
      {(creds === null) ? routingLogOut : routingLogIn}
    </ThemeProvider>
  );

  // return (
  //         <ThemeProvider theme={theme}>
  //           <GlobalStyles />
  //           {routingLogIn}
  //         </ThemeProvider>
  //     );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainApp);
