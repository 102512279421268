// import STUDENT from '../JsonData/student.json';
import * as ActionTypes from './ActionTypes';

export const Notif = (state = {
    toShow:false,
    message:""
}, action) => {
    switch (action.type) {
        case ActionTypes.SET_NOTIFMESSAGE:
            return {...state, message:action.payload};
        case ActionTypes.SET_NOTIFSHOW:
            return{...state, toShow:action.payload};

        default:
          return state;
      }
};