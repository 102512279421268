import { useState } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import { LogoutUser } from '../redux/ActionCreators';
import LoadingBar from './LoadingBar';

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    student: state.student,
    branches: state.branches,
    webinar_data: state.webinar_data,
    jobPost: state.jobPost,
    loadingBar: state.loadingBar,
    notif: state.notif,
    applications: state.applications,
    notifications: state.notifications
  };
};

const mapDispatchToProps = (dispatch) => ({
  LogoutUser: () => dispatch(LogoutUser())
  // fetchStudent: () => dispatch(fetchStudent())
});

const DashboardNavbar = ({
  onMobileNavOpen,
  loadingBar,
  jobPost,
  webinar_data,
  student,
  LogoutUser,
  ...rest
}) => {
  const [notifications] = useState([]);

  const isLoading = loadingBar.toShow || jobPost.isLoading || student.isLoading || webinar_data.isLoading;
  return (
    <>
      <AppBar elevation={0} {...rest}>
        {isLoading && <LoadingBar />}
        <Toolbar>
          <RouterLink to="/">
            <h1
              style={{
                fontFamily: 'Roboto, Helvetica, sans-serif',
                color: 'white'
              }}
            >
              TNP RM
            </h1>
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { lg: 'block', xs: 'none' } }}>
            <IconButton onClick={LogoutUser} color="inherit">
              <InputIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: { lg: 'none', xs: 'block' } }}>
            <IconButton color="inherit" onClick={onMobileNavOpen}>
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNavbar);
