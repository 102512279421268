import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import LinearProg from '../components/LoadingBar';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchProgressPost } from '../redux/ActionCreators';
import parse from 'html-react-parser';

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    student: state.student,
    branches: state.branches,
    webinar_data: state.webinar_data,
    jobPost: state.jobPost,
    loadingBar: state.loadingBar,
    notif: state.notif,
    applications: state.applications,
    notifications: state.notifications
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProgressPost: (jobId) => dispatch(fetchProgressPost(jobId))

  // fetchStudent: () => dispatch(fetchStudent())
});

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: 12,
    width: '90%'
    //   float: 'right'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold'
  },
  pos: {
    marginBottom: 12
  }
});

function ProgressPosts(props) {
  // console.log(props.jobPost);
  const classes = useStyles();
  const slug = useParams();
  const jobId = slug.jobPost;

  // var s='<br/><br/><br/> hey this is a new job post';

  useEffect(() => {
    if (props.jobPost.progressJobId !== jobId) {
      props.fetchProgressPost(jobId);
    }
    // else if((props.jobPost.progressPost.data.length !==0) || (props.jobPost.progressPost.data.length !==0 && props.jobPost.progressPost.data[0].jobId != jobId))
    // {
    //     props.fetchProgressPost(jobId);
    // }
  }, []);

  const navigate = useNavigate();
  // console.log(slug);
  if (JSON.stringify(props.jobPost.progressPost) === '{}') {
    return (
      <>
        <Box
          sx={{
            p: 4,
            width: '100%'
          }}
        >
          <Button
            sx={{
              mb: 4
            }}
            color="primary"
            variant="contained"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Box>
      </>
    );
  } else {
    const progessPosts = props.jobPost.progressPost.data;
    return (
      <>
        <Box
          sx={{
            p: 4,
            width: '100%'
          }}
        >
          <Button
            sx={{
              mb: 4
            }}
            color="primary"
            variant="contained"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          {progessPosts.map((dat, index) => {
            // console.log(index);
            // console.log(jsonJob);
            return (
              <Box sx={{ mb: 5 }}>
                <Card className={classes.root}>
                  <CardContent>
                    {/* {console.log(jsonJob)} */}

                    <Typography variant="h4" fontWeight={600} sx={{ mb: 2 }}>
                      {/* Round {index + 1} */}
                      {dat.progressType === "ppt" ? `Pre-Pacement Talk` : dat.progressType === "oa" ? `Online Assessment` : dat.progressType === "gd" ? `Group Discussion` : dat.progressType === "groupfly" ? `Group Fly` : `Interview`}
                    </Typography>
                    <Typography sx={{ mb: 1 }}> {parse(dat.postData)}</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      {/* <Typography>Type: {dat.progressType}</Typography> */}
                      <Typography>
                        Date :{' '}
                        {/* {console.log(dat.eventDate)} */}
                        {/* {console.log(new Date(dat.eventDate))} */}
                        {new Date(dat.eventDate).getDate() +
                          '-' +
                          (new Date(dat.eventDate).getMonth() + 1) +
                          '-' +
                          new Date(dat.eventDate).getFullYear() +
                          ' ' +
                          (new Date(dat.eventDate).getHours() >=10 ? new Date(dat.eventDate).getHours() : '0' + new Date(dat.eventDate).getHours())
                          +
                          ':' +
                          (new Date(dat.eventDate).getMinutes() >= 10 ? new Date(dat.eventDate).getMinutes() : '0' + new Date(dat.eventDate).getMinutes())}
                      </Typography>
                      <Typography>
                        Coordinated By : <a href={`mailto:${dat.coordinator.email}`}>{dat.coordinator.email}</a>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            );
          })}
        </Box>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressPosts);
