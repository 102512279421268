import { applyMiddleware, createStore, combineReducers } from 'redux';
// import { Reducer, initialState } from './reducer'

import { composeWithDevTools } from 'redux-devtools-extension';

import { Student } from './student';
import { Branches } from './branches';
import { JobPost } from './jobpost';
import { Webinar_data } from './webinar_data';
import { LoginDetails } from './loginDetails';
import { LoadingBar } from './loadingBar';
import { Notif } from './Notif';
import { Applications } from './Applications';
import {Notifications} from './notifications';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsBlacklist, actionsCreators and other options
});

export const ConfigureStore = () => {
  const store = createStore(
    combineReducers({
      loginDetails: LoginDetails,
      student: Student,
      branches: Branches,
      webinar_data: Webinar_data,
      jobPost: JobPost,
      loadingBar: LoadingBar,
      notif: Notif,
      applications:Applications,
      notifications: Notifications
    }), // reducer
    // applyMiddleware(thunk)
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};
