import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 150,
  },
});

function createData(sem,cgpa) {
  return { sem,cgpa };
}

const rows = [
  createData('Sem 1', 8.5),
  createData('Sem 2', 8.2),
  createData('Sem 3', 7.5),
  createData('Sem 4', 8.0),
  createData('Sem 5', 'NA'),
  createData('Sem 6', 'NA'),
  createData('Sem 7', 'NA'),
  createData('Sem 8', 'NA'),
];

export default function CgpaTable() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Semester</TableCell>
            <TableCell align="right">CGPA</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.sem}
              </TableCell>
              <TableCell align="right">{row.cgpa}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
