import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import jsonJobPosts from '../../JsonData/jobPost.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import './jobPostCard.css';
import { connect } from 'react-redux';
import Link from '@material-ui/core/Link';
import { fetchProgressPost } from '../../redux/ActionCreators';

const mapStateToProps = (state) => ({
  loginDetails: state.loginDetails,
  student: state.student,
  branches: state.branches,
  webinar_data: state.webinar_data,
  jobPost: state.jobPost,
  loadingBar: state.loadingBar,
  notif: state.notif,
  applications: state.applications,
  notifications: state.notifications
});

const mapDispatchToProps = (dispatch) => ({
  fetchProgressPost: (jobId) => dispatch(fetchProgressPost(jobId))

  // fetchStudent: () => dispatch(fetchStudent())
});

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: 12
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold'
  },
  pos: {
    marginBottom: 12
  }
});

const JobPostCard = (props) => {
  // console.log(props);
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  // const jobs=;

  //   const [jobs, setJobs] = useState();

  //   useEffect(() => {
  //     if (props.jobPost.jobs.data) {
  //       setJobs(props.jobPost.jobs.data);
  //       setCount(
  //           props.jobPost.jobs.data.length >= props.count * 10  ? 10 : props.jobPost.jobs.data.length > (props.count - 1) * 10  ? props.jobPost.jobs.data.length % 10 : 0
  //       );
  //       setT(props.jobPost.jobs.data.splice(0,0));
  //     }
  //   }, [props.jobPost.jobs]);

  //   const [count, setCount] = useState(0);

  //   const [t,setT] = useState();

  //   console.log(t);

  //   const handleProgressPost = (e) => {
  //     e.preventDefault();
  //     props.fetchProgressPost(jobId);
  //   };

  //   console.log(count);

  return (
    props.jobPost.jobs.data ? (
      <Grid container justify="center" alignItems="center" spacing={2}>
        {props.jobPost.jobs.data.map((job, index) => {
          // console.log(job)
          if (index >= (props.count - 1) * 10 && index < (props.count) * 10) {
            return (
              (job.btech || job.mtech || job.bdes || job.mdes || job.msc || job.mba || job.ba || job.ma) ? (
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Card className={classes.root}>
                      <CardContent>

                        <Box
                          sx={{
                            display: 'flex',
                            mb: 2
                          }}
                        >
                          <Avatar aria-label="recipe">
                            {/* <FontAwesomeIcon icon={faGoogle} /> */}
                            {job.company.name.charAt(0).toUpperCase()}
                          </Avatar>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              pl: 2
                            }}
                          >
                            <Typography variant="h5" fontWeight={600}>
                              {job.name}
                            </Typography>
                            <Typography>{job.company.name}</Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column'
                          }}
                        >
                          <Typography>
                            <Typography display="inline" fontWeight={600}>Posted on</Typography>
                            {' '}
                            :
                            {' '}
                            {`${new Date(job.applicationOpen).getDate()
                            }-${
                              new Date(job.applicationOpen).getMonth() + 1
                            }-${
                              new Date(job.applicationOpen).getFullYear()
                            } ${
                              new Date(job.applicationOpen).getHours() >= 10 ? new Date(job.applicationOpen).getHours() : `0${new Date(job.applicationOpen).getHours()}`

                            }:${
                              new Date(job.applicationOpen).getMinutes() >= 10 ? new Date(job.applicationOpen).getMinutes() : `0${new Date(job.applicationOpen).getMinutes()}`}`}
                          </Typography>
                          <Typography>
                            <Typography display="inline" fontWeight={600}>Deadline to Apply </Typography>
                            :
                            {' '}
                            {`${new Date(job.applicationClosed).getDate()
                            }-${
                              new Date(job.applicationClosed).getMonth() + 1
                            }-${
                              new Date(job.applicationClosed).getFullYear()
                            } ${
                              new Date(job.applicationClosed).getHours() >= 10 ? new Date(job.applicationClosed).getHours() : `0${new Date(job.applicationClosed).getHours()}`

                            }:${
                              new Date(job.applicationClosed).getMinutes() >= 10 ? new Date(job.applicationClosed).getMinutes() : `0${new Date(job.applicationClosed).getMinutes()}`}`}
                          </Typography>
                          <Typography>
                            <Typography display="inline" fontWeight={600}>CutOff</Typography>
                            {' '}
                            : &nbsp;
                            {job.btech ? (
                              <>
                                Btech :
                                {job.btechCutoff}
                              </>
                            ) : <></>}
                            {job.mtech ? (
                              <>
                                , &nbsp;Mtech :
                                {job.mtechCutoff}
                              </>
                            ) : <></>}
                            {job.mba ? (
                              <>
                                , &nbsp;MBA :
                                {job.mbaCutoff}
                              </>
                            ) : <></>}
                            {job.bdes ? (
                              <>
                                , &nbsp;BDes :
                                {job.bdesCutoff}
                              </>
                            ) : <></>}
                            {job.mdes ? (
                              <>
                                , &nbsp;MDes :
                                {job.mdesCutoff}
                              </>
                            ) : <></>}
                            {job.msc ? (
                              <>
                                , &nbsp;MSc :
                                {job.mscCutoff}
                              </>
                            ) : <></>}
                            {job.ba ? (
                              <>
                                , &nbsp;BA :
                                {job.baCutoff}
                              </>
                            ) : <></>}
                            {job.ma ? (
                              <>
                                , &nbsp;MA :
                                {job.maCutoff}
                              </>
                            ) : <></>}
                          </Typography>
                          {job.jobType === 'intern' ? (
                            <Typography>
                              <Typography display="inline" fontWeight={600}>Stipend</Typography>
                              {' '}
                              :
                              {' '}
                              {job.ctc}
                              {' '}
                              per Month
                            </Typography>
                          ) : (
                            <Typography>
                              CTC :
                              {job.ctc}
                              {' '}
                              LPA
                            </Typography>
                          )}
                        </Box>

                      </CardContent>
                      <CardActions
                        sx={{
                          justifyContent: 'space-between'
                        }}
                      >
                        <RouterLink to={`/app/jobs/${job._id}`}>
                          <Button size="small">Know More</Button>
                        </RouterLink>
                        <RouterLink to={`/app/jobs/${job._id}/progress`}>
                          <Button size="small">See Progress</Button>
                        </RouterLink>
                        {/* <Button onClick={handleProgressPost} size="small">See Progress</Button> */}
                      </CardActions>
                    </Card>
                  </Box>
                </Grid>
              ) : <></>
            );
          }
        })}
      </Grid>
    ) : <></>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(JobPostCard);
