import React, {useState,useEffect} from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
// import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';

import { Provider } from 'react-redux';
import { ConfigureStore } from './redux/configureStore';
import MainApp from './MainApp';

const store=ConfigureStore();

const App = () => {
  const routing = useRoutes(routes);

  return (
    <Provider store={store} >
      <MainApp />
    </Provider>
  );
};

export default App;