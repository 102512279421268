import axios from 'axios';
import * as ActionTypes from './ActionTypes';
import STUDENT from '../JsonData/student.json';
import { baseUrl } from '../baseUrl';

export const LogoutUser = () => (dispatch) => {
  dispatch(LoginLoading(true));
  dispatch(LoadingBar(true));
  setTimeout(() => {
    localStorage.removeItem('userJWT');
    // alert('Successfully Logged Out');
    dispatch(Logout('Successfully Logged Out'));
    dispatch(LoginLoading(false));
    dispatch(LoadingBar(false));
    window.location.href = '/login';
  }, 2000);
};

export const LoginUser = (name, password) => (dispatch) => {
  dispatch(LoadingBar(true));
  dispatch(LoginLoading(true));

  // console.log(baseUrl);

  setTimeout(() => {
    axios
      .post(`${baseUrl}/api/auth/login`, {
        rollNo: name,
        password
      })
      // .then(response => response.json())
      .then((response) => {
        // console.log(response);
        // const rep=response.json();
        // console.log(rep);
        // console.log(error);
        if (response.data.success) {
          localStorage.setItem(
            'userJWT',
            JSON.stringify(response.data.data.token)
          );
          dispatch(LoginLoading(false));

          dispatch(LoadingBar(false));
          dispatch(Login(response.data.data));

          // dispatch(fetchStudent());
          // window.location.href='/';
        }
      })
      .catch((error) => {
        // console.log(error);
        if (error.response) {
          // console.log(error.response.data.message);
          // alert(error.response.data.message);
          dispatch(ChangeNotifMessage(error.response.data.message));
          dispatch(ChangeNotifShow(true));
          // console.log("error");
          dispatch(LoginFailed('error.response.data.message'));
          dispatch(LoginLoading(false));
          // dispatch(LoginLoading(true));

          localStorage.removeItem('userJWT');
          // window.location.href = '/login';
          dispatch(LoadingBar(false));
          // setTimeout(() => {

          //     // window.location.href='/login';
          // }, 1000);
        }
      });
  }, 2000);
};

export const LoginLoading = (isLoadingvar) => ({
  type: ActionTypes.LOGIN_LOADING,
  payload: isLoadingvar
});

export const LoginFailed = (errmess) => ({
  type: ActionTypes.LOGIN_FAILED,
  payload: errmess
});

export const Login = (dat) => ({
  type: ActionTypes.LOGIN,
  payload: dat
});

export const Logout = (dat) => ({
  type: ActionTypes.LOGOUT,
  payload: dat
});

// export const login = (name,password) => ({
//     type: ActionTypes.LOGIN,
//     payload: {
//         name:name,
//         password:password
//     }
// });

export const fetchStudent = () => (dispatch) => {
  dispatch(StudentLoading(true));
  dispatch(LoadingBar(true));

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  // console.log("creds" + creds)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${creds}`
  };

  axios
    .get(`${baseUrl}/api/student/profile`, {
      headers
    })
    // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.success) {
        // console.log(response.data);
        dispatch(addStudent(response.data));
        dispatch(StudentLoading(false));
        dispatch(LoadingBar(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);
        dispatch(ChangeNotifMessage(error.response.data.message));
        dispatch(ChangeNotifShow(true));
        // console.log("error");
        dispatch(StudentFailed(error.response.data.message));
        dispatch(StudentLoading(false));
        localStorage.removeItem('userJWT');
        window.location.href = '/login';
        dispatch(LoadingBar(false));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const updateStudent = (
  gaurName,
  motName,
  fatName,
  dob,
  mobile,
  alternateEmail,
  placeOfBirth,
  languages,
  currAddLine1,
  currAddLine2,
  currAddState,
  currAddPost,
  permAddLine1,
  permAddLine2,
  permAddState,
  permAddPost
) => (dispatch) => {
  dispatch(StudentUpdating(true));
  dispatch(LoadingBar(true));

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  const dat = {
    guardianName: gaurName,
    mobile,
    motherName: motName,
    fatherName: fatName,
    dob,
    alternateEmail,
    languages,
    placeOfBirth,
    currentAddress: {
      line1: currAddLine1,
      line2: currAddLine2,
      state: currAddState,
      postalCode: currAddPost,
      country: ''
    },
    permanentAddress: {
      line1: permAddLine1,
      line2: permAddLine2,
      state: permAddState,
      postalCode: permAddPost,
      country: ''
    }
  };

  // console.log(dat);

  // {console.log(gaurName)}
  //         {console.log(mobile)}
  //         {console.log(skills)}
  //         {console.log(placeOfBirth)}
  //         {console.log(languages)}
  //         {console.log(currAddLine1)}
  //         {console.log(currAddLine2)}
  //         {console.log(currAddState)}
  //         {console.log(currAddPost)}
  //         {console.log(permAddLine1)}
  //         {console.log(permAddLine2)}
  //         {console.log(permAddState)}
  //         {console.log(permAddPost)}

  // console.log("creds" + creds)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${creds}`
  };

  axios
    .post(`${baseUrl}/api/student/profile`, dat, {
      headers
    })
  // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.success) {
        // console.log(response.data);
        // dispatch(addStudent(response.data));
        dispatch(ChangeNotifMessage('Successfully Updated the Profile'));
        dispatch(ChangeNotifShow(true));
        // dispatch(Login(creds));
        dispatch(fetchStudent());
        dispatch(LoadingBar(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);
        // dispatch(ChangeNotifMessage(error.response.data.message));
        // dispatch(ChangeNotifShow(true));
        // console.log("error");
        // dispatch(StudentFailed(error.response.data.message));
        // dispatch(StudentLoading(false));
        dispatch(ChangeNotifMessage("Couldn't Update the Profile"));
        dispatch(ChangeNotifShow(false));
        dispatch(StudentUpdating(false));
        // localStorage.removeItem('userJWT');
        // window.location.href='/login';
        dispatch(LoadingBar(false));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const updateSkills = (techSkills, otherSkills, pors, projectLinks, certificates) => (dispatch) => {
  dispatch(StudentUpdating(true));
  dispatch(LoadingBar(true));

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  const dat = {
    skills: {
      technicalSkills: techSkills,
      otherSkills,
      por: pors,
      projectLinks
    },
    certificates
  };

  // console.log(dat);

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${creds}`
  };

  axios
    .post(`${baseUrl}/api/student/profile`, dat, {
      headers
    })
  // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.success) {
        // console.log(response.data);
        // dispatch(addStudent(response.data));
        dispatch(ChangeNotifMessage('Successfully Updated the Skills'));
        dispatch(ChangeNotifShow(true));
        // dispatch(Login(creds));
        dispatch(fetchStudent());
        dispatch(LoadingBar(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);
        // dispatch(ChangeNotifMessage(error.response.data.message));
        // dispatch(ChangeNotifShow(true));
        // console.log("error");
        // dispatch(StudentFailed(error.response.data.message));
        // dispatch(StudentLoading(false));
        dispatch(ChangeNotifMessage("Couldn't Update the Skills"));
        dispatch(ChangeNotifShow(false));
        dispatch(StudentUpdating(false));
        // localStorage.removeItem('userJWT');
        // window.location.href='/login';
        dispatch(LoadingBar(false));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const updateProfilePic = (profilePic) => (dispatch) => {
  dispatch(StudentUpdating(true));
  dispatch(LoadingBar(true));

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  // console.log(dat);

  // {console.log(gaurName)}
  //         {console.log(mobile)}
  //         {console.log(skills)}
  //         {console.log(placeOfBirth)}
  //         {console.log(languages)}
  //         {console.log(currAddLine1)}
  //         {console.log(currAddLine2)}
  //         {console.log(currAddState)}
  //         {console.log(currAddPost)}
  //         {console.log(permAddLine1)}
  //         {console.log(permAddLine2)}
  //         {console.log(permAddState)}
  //         {console.log(permAddPost)}

  // console.log("creds" + creds)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${creds}`
  };

  axios
    .post(`${baseUrl}/api/uploads/updateProfPic`, profilePic, {
      headers
    })
    // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.status === 200) {
        // console.log(response.data);
        dispatch(
          ChangeNotifMessage('Successfully Updated the Profile Picture')
        );
        dispatch(ChangeNotifShow(true));

        // dispatch(addStudent(response.data));
        // dispatch(Login(creds));
        // dispatch(fetchStudent());
        dispatch(StudentUpdating(false));

        dispatch(LoadingBar(false));

        dispatch(fetchStudent());
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data);
        // alert(error.response.data);

        // console.log("error");
        // dispatch(StudentFailed(error.response.data.message));
        // dispatch(StudentLoading(false));
        dispatch(StudentUpdating(false));
        // localStorage.removeItem('userJWT');
        // window.location.href='/login';
        dispatch(LoadingBar(false));
        dispatch(ChangeNotifMessage("Couldn't Updat the Profile Picture"));
        dispatch(ChangeNotifShow(true));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};
export const updateResume = (resumeFile, resumeNumber) => (dispatch) => {
  dispatch(StudentUpdating(true));
  dispatch(LoadingBar(true));

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  // console.log(dat);

  // {console.log(gaurName)}
  //         {console.log(mobile)}
  //         {console.log(skills)}
  //         {console.log(placeOfBirth)}
  //         {console.log(languages)}
  //         {console.log(currAddLine1)}
  //         {console.log(currAddLine2)}
  //         {console.log(currAddState)}
  //         {console.log(currAddPost)}
  //         {console.log(permAddLine1)}
  //         {console.log(permAddLine2)}
  //         {console.log(permAddState)}
  //         {console.log(permAddPost)}

  // console.log("creds" + creds)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${creds}`,
    resume: resumeNumber
  };

  axios
    .post(`${baseUrl}/api/uploads/uploadResume`, resumeFile, {
      headers
    })
    // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.status === 200) {
        // console.log(response.data);
        // dispatch(addStudent(response.data));
        dispatch(
          ChangeNotifMessage(`Successfully Updated the ${resumeNumber}`)
        );
        dispatch(ChangeNotifShow(true));
        // dispatch(Login(creds));
        // dispatch(fetchStudent());
        dispatch(StudentUpdating(false));
        dispatch(LoadingBar(false));
        dispatch(fetchStudent());
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data);
        // alert(error.response.data);

        dispatch(ChangeNotifMessage(`Couldn't Update the ${resumeNumber}`));
        dispatch(ChangeNotifShow(true));
        // console.log("error");
        // dispatch(StudentFailed(error.response.data.message));
        // dispatch(StudentLoading(false));
        dispatch(StudentUpdating(false));
        // localStorage.removeItem('userJWT');
        // window.location.href='/login';
        dispatch(LoadingBar(false));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const StudentLoading = (dat) => ({
  type: ActionTypes.STUDENT_LOADING,
  payload: dat
});

export const StudentUpdating = (dat) => ({
  type: ActionTypes.UPDATE_STUDENT,
  payload: dat
});

export const StudentFailed = (errmess) => ({
  type: ActionTypes.STUDENT_FAILED,
  payload: errmess
});

export const addStudent = (user) => ({
  type: ActionTypes.ADD_STUDENT,
  payload: user
});

export const fetchJobs = (page) => (dispatch) => {
  dispatch(JobsLoading(true));
  dispatch(LoadingBar(true));

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  // console.log("creds" + creds)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${creds}`
  };

  axios
    .get(`${baseUrl}/api/company/jobPost?page=${page}`, {
      headers
    })
    // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.success) {
        // console.log(response.data);
        dispatch(addJobs(response.data));
        dispatch(JobsLoading(false));
        dispatch(LoadingBar(false));
        dispatch(changeJobPage(page));
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);
        dispatch(ChangeNotifMessage(error.response.data.message));
        dispatch(ChangeNotifShow(true));
        // console.log("error");
        dispatch(Jobsfailed(error.response.data.message));
        dispatch(JobsLoading(false));
        localStorage.removeItem('userJWT');
        window.location.href = '/login';
        dispatch(LoadingBar(false));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const fetchApplications = () => (dispatch) => {
  dispatch(ApplicationsLoading(true));
  dispatch(LoadingBar(true));

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  // console.log("creds" + creds)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${creds}`
  };

  axios
    .get(`${baseUrl}/api/application/myApplications`, {
      headers
    })
    // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.success) {
        // console.log(response.data);
        dispatch(addApplications(response.data));
        dispatch(ApplicationsLoading(false));
        dispatch(LoadingBar(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);
        dispatch(ChangeNotifMessage(error.response.data.message));
        dispatch(ChangeNotifShow(true));
        // console.log("error");
        dispatch(Applicationsfailed(error.response.data.message));
        dispatch(ApplicationsLoading(false));
        // localStorage.removeItem('userJWT');
        // window.location.href='/login';
        dispatch(LoadingBar(false));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const fetchSingleJob = (jobId) => (dispatch) => {
  dispatch(JobsLoading(true));
  dispatch(LoadingBar(true));

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  const dat = {
    jobId
  };

  console.log(dat);
  // console.log("creds" + creds)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${creds}`
  };

  axios
    .get(`${baseUrl}/api/company/jobPostSingle`, {
      params: dat,
      headers
    })
    // .then(response => response.json())
    .then((response) => {
      console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.success) {
        // console.log(response.data);
        dispatch(addSingleJob(response.data));
        dispatch(JobsLoading(false));
        dispatch(LoadingBar(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);
        dispatch(ChangeNotifMessage(error.response.data.message));
        dispatch(ChangeNotifShow(true));
        // console.log("error");
        dispatch(Jobsfailed(error.response.data.message));
        dispatch(JobsLoading(false));
        // localStorage.removeItem('userJWT');
        // window.location.href='/login';
        dispatch(LoadingBar(false));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const fetchSingleApplication = (applicationId) => (dispatch) => {
  dispatch(ApplicationsLoading(true));
  dispatch(LoadingBar(true));

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  const dat = {
    applicationId
  };

  // console.log("creds" + creds)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${creds}`
  };

  axios
    .get(`${baseUrl}/api/application/getApplication`, {
      params: dat,
      headers
    })
    // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.success) {
        // console.log(response.data);
        dispatch(addSingleApplication(response.data));
        dispatch(ApplicationsLoading(false));
        dispatch(LoadingBar(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);
        dispatch(ChangeNotifMessage(error.response.data.message));
        dispatch(ChangeNotifShow(true));
        // console.log("error");
        dispatch(Applicationsfailed(error.response.data.message));
        dispatch(ApplicationsLoading(false));
        // localStorage.removeItem('userJWT');
        // window.location.href='/login';
        dispatch(LoadingBar(false));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const fetchProgressPost = (jobId) => (dispatch) => {
  dispatch(JobsLoading(true));
  dispatch(LoadingBar(true));

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  const dat = {
    jobId
  };

  dispatch(addProgressJobId(jobId));

  // console.log("creds" + creds)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${creds}`
  };

  axios
    .get(`${baseUrl}/api/company/progressPost`, {
      params: dat,
      headers
    })
    // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.success) {
        // console.log(response.data);
        dispatch(addProgressPost(response.data));
        dispatch(JobsLoading(false));
        dispatch(LoadingBar(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);

        // console.log("error");
        dispatch(Jobsfailed(error.response.data.message));
        dispatch(JobsLoading(false));
        // localStorage.removeItem('userJWT');
        // window.location.href='/login';
        dispatch(LoadingBar(false));
        // window.location.href = '/app/dashboard';
        dispatch(ChangeNotifMessage(error.response.data.message));
        dispatch(ChangeNotifShow(true));
        // setTimeout(() => {

        // }, 2000);
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const applyForJob = (jobId, content, resumeNumber) => (dispatch) => {
  // dispatch(ApplicationsLoading(true));
  dispatch(LoadingBar(true));

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  const dat = {
    jobId,
    content,
    resume: resumeNumber
  };

  // console.log("creds" + creds)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${creds}`
  };

  axios
    .post(`${baseUrl}/api/application/apply`, dat, {
      headers
    })
    // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.resume) {
        // console.log(response.data);
        // dispatch(addSingleApplication(response.data));

        // dispatch(ApplicationsLoading(false));

        dispatch(fetchStudent());
        dispatch(fetchApplications());
        dispatch(LoadingBar(false));
        window.location.href = '/app/myApplications';
        dispatch(ChangeNotifMessage('Successfully Applied for the Job'));
        dispatch(ChangeNotifShow(true));
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);
        // console.log("error");
        // dispatch(Applicationsfailed(error.response.data.message));
        // dispatch(ApplicationsLoading(false));
        // localStorage.removeItem('userJWT');
        // window.location.href='/login';
        dispatch(ChangeNotifMessage(error.response.data.message));
        dispatch(ChangeNotifShow(true));
        dispatch(LoadingBar(false));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const updateApplication = (applicationId, jobId, content, resumeNumber) => (dispatch) => {
  // dispatch(ApplicationsLoading(true));
  dispatch(LoadingBar(true));

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  const datParams = {
    applicationId,
    jobId
  };

  const dat = {
    content,
    resume: resumeNumber
  };

  // console.log("creds" + creds)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${creds}`
  };

  axios
    .put(`${baseUrl}/api/application/apply`, dat, {
      params: datParams,
      headers
    })
  // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.resume) {
        dispatch(fetchSingleApplication(applicationId));
        dispatch(ChangeNotifMessage('Successfully Updated the Application'));
        dispatch(ChangeNotifShow(true));

        dispatch(LoadingBar(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);
        // console.log("error");
        // dispatch(Applicationsfailed(error.response.data.message));
        // dispatch(ApplicationsLoading(false));
        // localStorage.removeItem('userJWT');
        // window.location.href='/login';
        dispatch(ChangeNotifMessage(error.response.data.message));
        dispatch(ChangeNotifShow(true));
        dispatch(LoadingBar(false));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const cancelApplication = (applicationId, jobId) => (dispatch) => {
  // dispatch(ApplicationsLoading(true));
  dispatch(LoadingBar(true));

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  const dat = {
    applicationId,
    jobId
  };

  // console.log(dat);

  // console.log("creds" + creds)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${creds}`
  };

  axios
    .delete(`${baseUrl}/api/application/cancelApplication`, {
      params: dat,
      headers
    })
    // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.success) {
        // console.log(response.data);
        // dispatch(addSingleApplication(response.data));

        // dispatch(ApplicationsLoading(false));

        dispatch(ChangeNotifMessage('Successfully Withdrawed the Application'));
        dispatch(ChangeNotifShow(true));

        dispatch(fetchStudent());
        dispatch(fetchApplications());
        // dispatch(addProgressPost({}));
        dispatch(resetSingleApplication());
        dispatch(resetProgressPost());
        dispatch(LoadingBar(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);
        // console.log("error");
        // dispatch(Applicationsfailed(error.response.data.message));
        // dispatch(ApplicationsLoading(false));
        // localStorage.removeItem('userJWT');
        // window.location.href='/login';
        dispatch(ChangeNotifMessage(error.response.data.message));
        dispatch(ChangeNotifShow(true));
        dispatch(LoadingBar(false));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const updatePassword = (prevPass, newPass) => (dispatch) => {
  // dispatch(ApplicationsLoading(true));
  dispatch(LoadingBar(true));

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  const dat = {
    currentPassword: prevPass,
    newPassword: newPass
  };

  // console.log(dat);

  // console.log("creds" + creds)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${creds}`
  };

  axios
    .put(`${baseUrl}/api/student/userUpdate`, dat, {
      headers
    })
    // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.success) {
        // console.log(response.data);
        // dispatch(addSingleApplication(response.data));

        // dispatch(ApplicationsLoading(false));

        dispatch(ChangeNotifMessage('Successfully Update the Password'));
        dispatch(ChangeNotifShow(true));

        // dispatch(fetchStudent());
        // dispatch(fetchApplications());
        // dispatch(fetchJobs());
        // // dispatch(addProgressPost({}));
        // dispatch(resetSingleApplication());
        // dispatch(resetProgressPost());

        setTimeout(() => {
          dispatch(LoadingBar(false));
          dispatch(LogoutUser());
        }, 2000);
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);
        // console.log("error");
        // dispatch(Applicationsfailed(error.response.data.message));
        // dispatch(ApplicationsLoading(false));
        // localStorage.removeItem('userJWT');
        // window.location.href='/login';
        dispatch(ChangeNotifMessage("Couldn't Update the Password"));
        dispatch(ChangeNotifShow(true));
        dispatch(LoadingBar(false));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const updateSecurityQuestions = (qs1, qs2, ans1, ans2) => (dispatch) => {
  // dispatch(ApplicationsLoading(true));
  dispatch(LoadingBar(true));

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  const dat = {
    security: {
      question1: qs1,
      question2: qs2,
      answer1: ans1,
      answer2: ans2
    }
  };

  // console.log(dat);

  // console.log("creds" + creds)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${creds}`
  };

  axios
    .put(`${baseUrl}/api/student/userUpdate`, dat, {
      headers
    })
    // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.success) {
        // console.log(response.data);
        // dispatch(addSingleApplication(response.data));

        // dispatch(ApplicationsLoading(false));

        dispatch(
          ChangeNotifMessage('Successfully Update the Security Questions')
        );
        dispatch(ChangeNotifShow(true));

        // dispatch(fetchStudent());
        // dispatch(fetchApplications());
        // dispatch(fetchJobs());
        // // dispatch(addProgressPost({}));
        // dispatch(resetSingleApplication());
        // dispatch(resetProgressPost());
        dispatch(LoadingBar(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);
        // console.log("error");
        // dispatch(Applicationsfailed(error.response.data.message));
        // dispatch(ApplicationsLoading(false));
        // localStorage.removeItem('userJWT');
        // window.location.href='/login';
        dispatch(ChangeNotifMessage("Couldn't Update the Security Questions"));
        dispatch(ChangeNotifShow(true));
        dispatch(LoadingBar(false));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const forgotPassword = (emailId) => (dispatch) => {
  // dispatch(ApplicationsLoading(true));
  dispatch(LoadingBar(true));

  const dat = {
    email: emailId
  };

  // console.log(dat);

  axios
    .post(`${baseUrl}/api/auth/forgot`, dat)
    // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.status) {
        // console.log(response.data);
        // dispatch(addSingleApplication(response.data));

        // dispatch(ApplicationsLoading(false));

        dispatch(ChangeNotifMessage(response.data.status));
        dispatch(ChangeNotifShow(true));

        // dispatch(fetchStudent());
        // dispatch(fetchApplications());
        // dispatch(fetchJobs());
        // // dispatch(addProgressPost({}));
        // dispatch(resetSingleApplication());
        // dispatch(resetProgressPost());
        dispatch(LoadingBar(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);
        // console.log("error");
        // dispatch(Applicationsfailed(error.response.data.message));
        // dispatch(ApplicationsLoading(false));
        // localStorage.removeItem('userJWT');
        // window.location.href='/login';
        dispatch(ChangeNotifMessage(error.response.data.message));
        dispatch(ChangeNotifShow(true));
        dispatch(LoadingBar(false));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const checkResetHash = (hashId) => (dispatch) => {
  // dispatch(ApplicationsLoading(true));
  dispatch(LoadingBar(true));

  const dat = {
    hash: hashId
  };

  // console.log(dat);

  axios
    .post(`${baseUrl}/api/auth/passwordreset`, dat)
    // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.userid) {
        // console.log(response.data);
        // dispatch(addSingleApplication(response.data));

        // dispatch(ApplicationsLoading(false));

        dispatch(setUserId(response.data.userid));

        // dispatch(ChangeNotifMessage(response.data.status));
        // dispatch(ChangeNotifShow(true));

        // dispatch(fetchStudent());
        // dispatch(fetchApplications());
        // dispatch(fetchJobs());
        // // dispatch(addProgressPost({}));
        // dispatch(resetSingleApplication());
        // dispatch(resetProgressPost());
        dispatch(LoadingBar(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);
        // console.log("error");
        // dispatch(Applicationsfailed(error.response.data.message));
        // dispatch(ApplicationsLoading(false));
        // localStorage.removeItem('userJWT');
        // window.location.href='/login';
        dispatch(setErrUserId(error.response.data.message));
        dispatch(ChangeNotifMessage(error.response.data.message));
        dispatch(ChangeNotifShow(true));
        dispatch(LoadingBar(false));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const resetPassword = (hashId, userId, pass) => (dispatch) => {
  // dispatch(ApplicationsLoading(true));
  dispatch(LoadingBar(true));

  const dat = {
    hash: hashId,
    userid: userId,
    password: pass
  };

  // console.log(dat);

  axios
    .post(`${baseUrl}/api/auth/passwordSuccess`, dat)
    // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.status) {
        // console.log(response.data);
        // dispatch(addSingleApplication(response.data));

        // dispatch(ApplicationsLoading(false));

        // dispatch(setUserId(response.data.userid));

        dispatch(ChangeNotifMessage(response.data.status));
        dispatch(ChangeNotifShow(true));

        // dispatch(fetchStudent());
        // dispatch(fetchApplications());

        setTimeout(() => {
          dispatch(LoadingBar(false));
          window.location.href = '/login';
        }, 1000);
        // dispatch(fetchJobs());
        // // dispatch(addProgressPost({}));
        // dispatch(resetSingleApplication());
        // dispatch(resetProgressPost());
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);
        // console.log("error");
        // dispatch(Applicationsfailed(error.response.data.message));
        // dispatch(ApplicationsLoading(false));
        // localStorage.removeItem('userJWT');
        // window.location.href='/login';
        dispatch(ChangeNotifMessage(error.response.data.message));
        dispatch(ChangeNotifShow(true));
        dispatch(LoadingBar(false));
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const setUserId = (dat) => ({
  type: ActionTypes.SET_USERID,
  payload: dat
});

export const ApplicationsLoading = (dat) => ({
  type: ActionTypes.APPLICATION_LOADING,
  payload: dat
});

export const Applicationsfailed = (errmess) => ({
  type: ActionTypes.APPLICATION_FAILED,
  payload: errmess
});

export const addApplications = (dat) => ({
  type: ActionTypes.GET_APPPLICATIONS,
  payload: dat
});

export const setErrUserId = (dat) => ({
  type: ActionTypes.SET_ERRUSERID,
  payload: dat
});

export const addProgressPost = (dat) => ({
  type: ActionTypes.ADD_PROGRESS,
  payload: dat
});

export const resetProgressPost = () => ({
  type: ActionTypes.RESET_PROGRESS
});

export const resetSingleApplication = () => ({
  type: ActionTypes.RESET_SINGLEDAT
});

export const addSingleApplication = (dat) => ({
  type: ActionTypes.GET_SINGLEAPPPLICATION,
  payload: dat
});

export const addSingleJob = (dat) => ({
  type: ActionTypes.GET_SINGLEJOB,
  payload: dat
});

export const resetSingleJob = (dat) => ({
  type: ActionTypes.RESET_SINGLEJOB
});

export const JobsLoading = (dat) => ({
  type: ActionTypes.JOBS_LOADING,
  payload: dat
});

export const Jobsfailed = (errmess) => ({
  type: ActionTypes.JOBS_FAILED,
  payload: errmess
});

export const addJobs = (jobs) => ({
  type: ActionTypes.ADD_JOBS,
  payload: jobs
});

export const addProgressJobId = (dat) => ({
  type: ActionTypes.SET_PROGRESSJOB,
  payload: dat
});

export const fetchWebinars = (page) => (dispatch) => {
  dispatch(WebinarsLoading(true));
  dispatch(LoadingBar(true));

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  // console.log("creds" + creds)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${creds}`
  };

  axios
    .get(`${baseUrl}/api/company/miscPost?page=${page}`, {
      headers
    })
    // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.success) {
        // console.log(response.data);
        dispatch(addWebinars(response.data));
        dispatch(WebinarsLoading(false));
        dispatch(changeWebinarPage(page));
        // dispatch(changeDashNotifDisplay(false));
        dispatch(LoadingBar(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);
        dispatch(ChangeNotifMessage(error.response.data.message));
        dispatch(ChangeNotifShow(true));
        // console.log("error");
        dispatch(Webinarsfailed(error.response.data.message));
        dispatch(WebinarsLoading(false));
        localStorage.removeItem('userJWT');
        window.location.href = '/login';
        dispatch(LoadingBar(false));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};
export const WebinarsLoading = (dat) => ({
  type: ActionTypes.WEBINARS_LOADING,
  payload: dat
});

export const Webinarsfailed = (errmess) => ({
  type: ActionTypes.WEBINARS_FAILED,
  payload: errmess
});

export const addWebinars = (webinars) => ({
  type: ActionTypes.ADD_WEBINARS,
  payload: webinars
});

export const LoadingBar = (dat) => ({
  type: ActionTypes.LOADING_BAR,
  payload: dat
});

export const ChangeNotifShow = (dat) => ({
  type: ActionTypes.SET_NOTIFSHOW,
  payload: dat
});

export const ChangeNotifMessage = (dat) => ({
  type: ActionTypes.SET_NOTIFMESSAGE,
  payload: dat
});

export const fetchNotifications = (notifPage) => (dispatch) => {
  dispatch(NotificationsLoading(true));
  dispatch(LoadingBar(true));

  // console.log("page " + notifPage);

  const creds = JSON.parse(localStorage.getItem('userJWT'));

  // console.log("creds" + creds)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${creds}`
  };

  axios
    .get(`${baseUrl}/api/student/notifications?page=${notifPage}`, {
      headers
    })
    // .then(response => response.json())
    .then((response) => {
      // console.log(response);
      // const rep=response.json();
      // console.log(rep);
      // console.log(error);
      if (response.data.success) {
        // console.log(response.data);
        dispatch(addNotifications(response.data));
        dispatch(NotificationsLoading(false));
        dispatch(LoadingBar(false));
        dispatch(changeNotificationPage(notifPage));
        // dispatch(changeDashNotifDisplay(true));
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data.message);
        // alert(error.response.data.message);
        dispatch(ChangeNotifMessage(error.response.data.message));
        dispatch(ChangeNotifShow(true));
        // console.log("error");
        dispatch(NotificationsFailed(error.response.data.message));
        dispatch(NotificationsLoading(false));
        // localStorage.removeItem('userJWT');
        // window.location.href = '/login';
        dispatch(LoadingBar(false));
        // setTimeout(() => {
        //     dispatch(StudentLoading(false));
        //     window.location.href='/login';
        // }, 1000);
      }
    });
  // dispatch(StudentLoading(true));

  // setTimeout(() => {
  //     dispatch(addStudent(STUDENT))
  // },2000)
};

export const changeJobPage = (dat) => ({
  type: ActionTypes.SET_JOBPAGE,
  payload: dat
});

export const changeNotificationPage = (dat) => ({
  type: ActionTypes.SET_NOTIFICATIONPAGE,
  payload: dat
});

export const changeWebinarPage = (dat) => ({
  type: ActionTypes.SET_WEBINARPAGE,
  payload: dat
});

export const changeDashNotifDisplay = (dat) => ({
  type: ActionTypes.SET_DASHNOTIFDISP,
  payload: dat
});

export const addNotifications = (dat) => ({
  type: ActionTypes.ADD_NOTIFICATIONS,
  payload: dat
});

export const NotificationsLoading = (dat) => ({
  type: ActionTypes.NOTIFICATIONS_LOADING,
  payload: dat
});

export const NotificationsFailed = (dat) => ({
  type: ActionTypes.NOTIFICATIONS_FAILED,
  payload: dat
});
