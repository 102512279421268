// import STUDENT from '../JsonData/student.json';
import * as ActionTypes from './ActionTypes';

export const LoginDetails = (state = {
    isLoading:false,
    errmess:null,
    dat:{},
    userId:"",
    errUserId:""
}, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN:
            return {...state, isLoading:false, dat:action.payload};
        case ActionTypes.LOGIN_LOADING:
            return{...state, isLoading:action.payload}

        case ActionTypes.LOGIN_FAILED:
            return{...state, errmess:action.payload}

        case ActionTypes.LOGOUT:
            return{...state, errmess:action.payload, dat:{}}

        case ActionTypes.SET_USERID:
            return{...state, userId:action.payload}

        case ActionTypes.SET_ERRUSERID:
            return{...state, errUserId:action.payload}  

        default:
          return state;
      }
};