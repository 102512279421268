import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
// import Logo from './Logo';
import { connect } from 'react-redux';
import LoadingBar from './LoadingBar';

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    student: state.student,
    branches: state.branches,
    webinar_data: state.webinar_data,
    jobPost: state.jobPost,
    loadingBar: state.loadingBar,
    notif: state.notif,
    applications: state.applications,
    notifications: state.notifications
  };
};

const MainNavbar = (props) => (
  <AppBar elevation={0} {...props}>
    {props.loadingBar.toShow ||
    props.jobPost.isLoading ||
    props.student.isLoading ||
    props.webinar_data.isLoading ? (
      <LoadingBar />
    ) : (
      <></>
    )}
    <Toolbar sx={{ height: 64 }}>
      <RouterLink to="/">
        {/* <Logo /> */}
        <Typography sx={{
          color:'white'
        }} variant="h2">TNP RM</Typography>
      </RouterLink>
    </Toolbar>
  </AppBar>
);

export default connect(mapStateToProps)(MainNavbar);
