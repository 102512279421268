import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import student from '../../JsonData/student.json';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CgpaTable from './CgpaTable';
import './commonDat.css';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button'
import { updateSkills } from '../../redux/ActionCreators';
// import LoadingBar from '../components/LoadingBar';
import {Trash2 as DeleteIcon} from 'react-feather';

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    student: state.student,
    branches: state.branches,
    webinar_data: state.webinar_data,
    jobPost: state.jobPost,
    loadingBar: state.loadingBar,
    notif: state.notif,
    applications: state.applications,
    notifications: state.notifications
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSkills: (
    techSkills,otherSkills,pors,projectLinks, certificates
  ) =>
    dispatch(
      updateSkills(
        techSkills,otherSkills,pors,projectLinks, certificates
      )
    )

  // fetchStudent: () => dispatch(fetchStudent())
});

function CoCurricularDat(props) {
  const [languageSelect, setLanguageSelect] = useState('English');
  const userDat = props.student.student.data;

  const [tempTechSkill, setTempTechSkill] = useState("");
  const [tempOtherSkill, setTempOtherSkill] = useState("");
  const [tempPor, setTempPor] = useState("");
  const [tempProjectLink, setTempProjectLink] = useState("");

  const [techSkills, setTechSkills] = useState(userDat.skills.technicalSkills);
  const [otherSkills, setOtherSkills] = useState(userDat.skills.otherSkills);
  const [pors, setPors] = useState(userDat.skills.por);
  const [projectLinks, setProjectLinks] = useState(userDat.skills.projectLinks);

  const [certiLink, setCertiLink] = useState(userDat.certificates);

  const handleCertiLink = (e) => {
    e.preventDefault();
    setCertiLink(e.target.value);
  }

  const handleTempTech = (e) => {
    setTempTechSkill(e.target.value);
  }

  const handleChangeTechSkills = (e) => {
    e.preventDefault();
    // console.log(tempLang)
    if (tempTechSkill.length > 0) {
      setTechSkills(techSkills.concat(tempTechSkill));
    }
    // console.log(languages);
    setTempTechSkill("");
  };

  const removeTechSkills = (e) => {
    setTechSkills(techSkills.filter(function(lang) { 
        return lang !== e
    }));
  }

  const removeOtherSkill = (e) => {
    setOtherSkills(otherSkills.filter(function(lang) { 
        return lang !== e
    }));
  }

  const removePor = (e) => {
    setPors(pors.filter(function(lang) { 
        return lang !== e
    }));
  }

  const removeProjectLinks = (e) => {
    setProjectLinks(projectLinks.filter(function(lang) { 
        return lang !== e
    }));
  }

  const handleTempOther = (e) => {
    setTempOtherSkill(e.target.value);
  }

  const handleChangeOtherSkills = (e) => {
    e.preventDefault();
    // console.log(tempLang)
    if (tempOtherSkill.length > 0) {
      setOtherSkills(otherSkills.concat(tempOtherSkill));
    }
    // console.log(languages);
    setTempOtherSkill("");
  };

  const handleTempPor = (e) => {
    setTempPor(e.target.value);
  }

  const handleChangePors = (e) => {
    e.preventDefault();
    // console.log(tempLang)
    if (tempPor.length > 0) {
      setPors(pors.concat(tempPor));
    }
    // console.log(languages);
    setTempPor("");
  };

  const handleTempProjectLink = (e) => {
    setTempProjectLink(e.target.value);
  }

  const handleChangeProjectLinks = (e) => {
    e.preventDefault();
    // console.log(tempLang)
    if (tempProjectLink.length > 0) {
      setProjectLinks(projectLinks.concat(tempProjectLink));
    }
    // console.log(languages);
    setTempProjectLink("");
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    props.updateSkills(techSkills,otherSkills,pors,projectLinks, certiLink);
  }

  if (userDat) {
    // console.log(tempTechSkill);
    // console.log(tempOtherSkill);
    // console.log(tempPor);
    // console.log(tempProjectLink);
    // console.log(techSkills);
    // console.log(otherSkills);
    // console.log(pors);
    // console.log(projectLinks);
    return (
      <>
      <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
            sx={{
              mb: 3
            }}
          >
            <Grid className="multiLineText" item sm={12} md={12}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold'
                }}
              >
                Technical Skills
              </Typography>
            </Grid> 
            {techSkills.length === 0 ? <Grid className="multiLineText" item sm={12} md={12}>
              <Typography>No Technical Skills provided currently</Typography>
            </Grid>:<Grid className="multiLineText" item sm={12} md={12}>
              <List
                sx={{
                  maxHeight: 300,
                  overflow: 'auto'
                }}
                component="nav"
                aria-label="secondary mailbox folders"
              >
                {techSkills.map((skill) => {
                  return (
                    <ListItem
                      sx={{
                        border: '1px solid #cacbcd'
                      }}
                    >
                      <ListItemText primary={`${skill}`} />
                      <DeleteIcon style={{cursor:'pointer'}} onClick={() => removeTechSkills(skill)} />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>}
            <Grid className="multiLineText" item sm={10} md={10}>
              <TextField className="personalText"
                InputProps={{
                  readOnly: false
                }}
                multiline
                fullWidth="true"
                onChange={handleTempTech}
                value={tempTechSkill}
                rowsMax={4}
                defaultValue=""
                label="Add into Technical Skills"
              ></TextField>
            </Grid>
            <Grid className="multiLineText" item sm={2} md={2}>
              <Button onClick={handleChangeTechSkills} variant="contained">
                Add
              </Button>
            </Grid>
           <Grid className="multiLineText" item sm={12} md={12}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold'
                }}
              >
                Other Skills
              </Typography>
            </Grid> 
            {otherSkills.length === 0 ? <Grid className="multiLineText" item sm={12} md={12}>
              <Typography>No Other Skills provided currently</Typography>
            </Grid>:<Grid className="multiLineText" item sm={12} md={12}>
              <List
                sx={{
                  maxHeight: 300,
                  overflow: 'auto'
                }}
                component="nav"
                aria-label="secondary mailbox folders"
              >
                {otherSkills.map((skill) => {
                  return (
                    <ListItem
                      sx={{
                        border: '1px solid #cacbcd'
                      }}
                    >
                      <ListItemText primary={`${skill}`} />
                      <DeleteIcon style={{cursor:'pointer'}} onClick={() => removeOtherSkill(skill)} />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>}

            <Grid className="multiLineText" item sm={10} md={10}>
              <TextField className="personalText"
                InputProps={{
                  readOnly: false
                }}
                multiline
                fullWidth="true"
                onChange={handleTempOther}
                value={tempOtherSkill}
                rowsMax={4}
                defaultValue=""
                label="Add into Other Skills"
              ></TextField>
            </Grid>
            <Grid className="multiLineText" item sm={2} md={2}>
              <Button onClick={handleChangeOtherSkills} variant="contained">
                Add
              </Button>
            </Grid>
            
            <Grid className="multiLineText" item sm={12} md={12}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold'
                }}
              >
                Position of Responsibilities
              </Typography>
            </Grid> 
            {pors.length === 0 ? <Grid className="multiLineText" item sm={12} md={12}>
              <Typography>No Position of Respnsiblities provided currently</Typography>
            </Grid>:<Grid className="multiLineText" item sm={12} md={12}>
              <List
                sx={{
                  maxHeight: 300,
                  overflow: 'auto'
                }}
                component="nav"
                aria-label="secondary mailbox folders"
              >
                {pors.map((skill) => {
                  return (
                    <ListItem
                      sx={{
                        border: '1px solid #cacbcd'
                      }}
                    >
                      <ListItemText primary={`${skill}`} />
                      <DeleteIcon style={{cursor:'pointer'}} onClick={() => removePor(skill)} />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>}
            <Grid className="multiLineText" item sm={10} md={10}>
              <TextField className="personalText"
                InputProps={{
                  readOnly: false
                }}
                multiline
                fullWidth="true"
                onChange={handleTempPor}
                value={tempPor}
                rowsMax={4}
                defaultValue=""
                label="Add into Position of Responsibilities"
              ></TextField>
            </Grid>
            <Grid className="multiLineText" item sm={2} md={2}>
              <Button onClick={handleChangePors} variant="contained">
                Add
              </Button>
            </Grid>
            <Grid className="multiLineText" item sm={12} md={12}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold'
                }}
              >
                Project Links / Portfolio
              </Typography>
            </Grid> 
            {projectLinks.length === 0 ? <Grid className="multiLineText" item sm={12} md={12}>
              <Typography>No Project Links provided currently</Typography>
            </Grid>:<Grid className="multiLineText" item sm={12} md={12}>
              <List
                sx={{
                  maxHeight: 300,
                  overflow: 'auto'
                }}
                component="nav"
                aria-label="secondary mailbox folders"
              >
                {projectLinks.map((skill) => {
                  return (
                    <ListItem
                      sx={{
                        border: '1px solid #cacbcd'
                      }}
                    >
                      <ListItemText primary={`${skill}`} />
                      <DeleteIcon style={{cursor:'pointer'}} onClick={() => removeProjectLinks(skill)} />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>}

            <Grid className="multiLineText" item sm={10} md={10}>
              <TextField className="personalText"
                InputProps={{
                  readOnly: false
                }}
                multiline
                fullWidth="true"
                onChange={handleTempProjectLink}
                value={tempProjectLink}
                rowsMax={4}
                defaultValue=""
                label="Add into Project Links"
              ></TextField>
            </Grid>
            <Grid className="multiLineText" item sm={2} md={2}>
              <Button onClick={handleChangeProjectLinks} variant="contained">
                Add
              </Button>
            </Grid>

            <Grid className="multiLineText" item sm={12} md={12}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold'
                }}
              >
                Certificates Link
              </Typography>
            </Grid> 

            <Grid className="multiLineText" item sm={12} md={12}>
              <TextField className="personalText"
                InputProps={{
                  readOnly: false
                }}
                multiline
                fullWidth="true"
                onChange={handleCertiLink}
                value={certiLink}
                rowsMax={4}
                label="Certificates Link"
              ></TextField>
            </Grid>

            <Grid className="multiLineText" item sm={12} md={12}>
              <Button variant="contained" onClick={handleUpdate}>Update</Button>
            </Grid> 
            
          </Grid>
      </>
    );
  } else {
    return <></>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoCurricularDat);
