import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { useParams } from 'react-router';
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import parse from 'html-react-parser';
import NotFound from 'src/pages/NotFound';
import { applyForJob, fetchSingleJob } from '../redux/ActionCreators';
import EligibleTable from '../pageComponents/jobs/EligibleTable';

const mapStateToProps = (state) =>
  // console.log(state)
  ({
    loginDetails: state.loginDetails,
    student: state.student,
    branches: state.branches,
    webinar_data: state.webinar_data,
    jobPost: state.jobPost,
    loadingBar: state.loadingBar,
    notif: state.notif,
    applications: state.applications,
    notifications: state.notifications
  });
const mapDispatchToProps = (dispatch) => ({
  applyForJob: (jobId, content, resumeNumber) => dispatch(applyForJob(jobId, content, resumeNumber)),
  fetchSingleJob: (jobId) => dispatch(fetchSingleJob(jobId))
});

function JobDetails(props) {
  // console.log(props)
  // console.log(props.jobPost.singleJob.data)
  const slug = useParams();
  // console.log(slug);
  // const jobPost = jsonJobPosts[slug.jobPost-1];
  const [jobPost, setJobPost] = useState();

  const [resumeNumber, setResumeNumber] = useState('');
  const [content, setContent] = useState();

  const [dialogOpen, setDialogOpen] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (JSON.stringify(props.jobPost.singleJob) === '{}') {
      // console.log("a");
      props.fetchSingleJob(slug.jobPost);
    } else if (props.jobPost.singleJob.data._id != slug.jobPost) {
      // console.log(props.jobPost.singleJob.data._id);
      // console.log(slug.jobPost);
      props.fetchSingleJob(slug.jobPost);
      // console.log("b");
    }
  }, [props.jobPost.singleJob]);

  // useEffect(() => {
  //   console.log(props.jobPost.jobs.data);
  //   if (props.jobPost.jobs.data) {
  //     console.log(slug.jobPost)
  //     setJobPost(
  //       props.jobPost.jobs.data.find(({ _id }) => _id === slug.jobPost)
  //     );
  //   }
  // }, [props.jobPost.jobs]);

  const dollarIndianLocale = Intl.NumberFormat('en-IN');
  const navigate = useNavigate();

  const handleClose = () => {
    setDialogOpen(false);
  };

  const openApplyDialog = (e) => {
    e.preventDefault();
    setDialogOpen(true);
  };

  const setResumeNum1 = (e) => {
    e.preventDefault();
    if (resumeNumber === 'resume1') {
      setResumeNumber('');
    } else {
      setResumeNumber('resume1');
    }
  };

  const setResumeNum2 = (e) => {
    e.preventDefault();
    if (resumeNumber === 'resume2') {
      setResumeNumber('');
    } else {
      setResumeNumber('resume2');
    }
  };

  const setResumeNum3 = (e) => {
    e.preventDefault();
    if (resumeNumber === 'resume3') {
      setResumeNumber('');
    } else {
      setResumeNumber('resume3');
    }
  };

  const changeContent = (e) => {
    e.preventDefault();
    setContent(e.target.value);
  };

  const handleApply = (e) => {
    e.preventDefault();
    // console.log(props.jobPost._id);
    props.applyForJob(props.jobPost.singleJob.data._id, content, resumeNumber);
    handleClose();
  };

  // console.log(jobPost);

  if (JSON.stringify(props.jobPost.singleJob) === '{}' || props.loadingBar.toShow || JSON.stringify(props.student.student) === '{}') {
    return <></>;
  }
  return (
    (props.jobPost.singleJob.data.btech || props.jobPost.singleJob.data.mtech || props.jobPost.singleJob.data.bdes || props.jobPost.singleJob.data.mdes || props.jobPost.singleJob.data.msc || props.jobPost.singleJob.data.mba || props.jobPost.singleJob.data.ba || props.jobPost.singleJob.data.ma)
      ? (
        <Box
          sx={{
            p: 4
          }}
        >
          {/* <ResponsiveDialog isOpen={dialogOpen} /> */}
          <div>
            <Dialog
              fullScreen={fullScreen}
              open={dialogOpen}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
                <Typography variant="h3">
                  <strong>
                    Apply for
                    {' '}
                    {props.jobPost.singleJob.data.name}
                    {' '}
                    at
                    {' '}
                    {props.jobPost.singleJob.data.company.name}
                  </strong>
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Typography
                  variant="h4"
                  sx={{
                    marginBottom: 2
                  }}
                >
                  Select Your Resume
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                    marginBottom: 4
                  }}
                >
                  {props.student.student.data.resume.resume1 === ''
                && props.student.student.data.resume.resume2 === ''
                && props.student.student.data.resume.resume3 === '' ? (
                  <Typography>Please upload any one Resume</Typography>
                    ) : (
                      <></>
                    )}
                  {resumeNumber === 'resume1' ? (
                    <Chip
                      avatar={<Avatar>1</Avatar>}
                      label="Resume 1"
                      clickable
                    // variant="outlined"
                      onClick={setResumeNum1}
                      color="primary"
                      deleteIcon={<DoneIcon />}
                    />
                  ) : props.student.student.data.resume.resume1 === '' ? (
                    <></>
                  ) : (
                    <Chip
                      avatar={<Avatar>1</Avatar>}
                      label="Resume 1"
                      variant="outlined"
                      clickable
                      onClick={setResumeNum1}
                      color="primary"
                      deleteIcon={<DoneIcon />}
                    />
                  )}

                  {resumeNumber === 'resume2' ? (
                    <Chip
                      avatar={<Avatar>2</Avatar>}
                      label="Resume 2"
                      clickable
                    // variant="outlined"
                      onClick={setResumeNum2}
                      color="primary"
                      deleteIcon={<DoneIcon />}
                    />
                  ) : props.student.student.data.resume.resume2 === '' ? (
                    <></>
                  ) : (
                    <Chip
                      avatar={<Avatar>2</Avatar>}
                      label="Resume 2"
                      variant="outlined"
                      clickable
                      onClick={setResumeNum2}
                      color="primary"
                      deleteIcon={<DoneIcon />}
                    />
                  )}

                  {resumeNumber === 'resume3' ? (
                    <Chip
                      avatar={<Avatar>3</Avatar>}
                      label="Resume 3"
                      clickable
                      onClick={setResumeNum3}
                      color="primary"
                      deleteIcon={<DoneIcon />}
                    />
                  ) : props.student.student.data.resume.resume3 === '' ? (
                    <></>
                  ) : (
                    <Chip
                      avatar={<Avatar>3</Avatar>}
                      label="Resume 3"
                      variant="outlined"
                      clickable
                      onClick={setResumeNum3}
                      color="primary"
                      deleteIcon={<DoneIcon />}
                    />
                  )}
                </Box>
                {/* <Typography
                  variant="h4"
                  sx={{
                    marginBottom: 2
                  }}
                >
                  Write Content for Application
                </Typography>
                <TextField
                  multiline
                  rowsMax={10}
                  fullWidth="true"
                  onChange={changeContent}
                  value={content}
                /> */}
              </DialogContent>
              <DialogActions>
                {resumeNumber === 'resume1'
              || resumeNumber === 'resume2'
              || resumeNumber === 'resume3' ? (
                    props.jobPost.singleJob.applied ? (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled
                        autoFocus
                      >
                        Applied
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={handleApply}
                        color="primary"
                        autoFocus
                      >
                        Apply
                      </Button>
                    )
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      autoFocus
                      disabled
                    >
                      Apply
                    </Button>
                  )}
              </DialogActions>
            </Dialog>
          </div>
          <Button
            sx={{
              mb: 4
            }}
            color="primary"
            variant="contained"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Box
            sx={{
              mb: 1,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box>
              <Typography
                variant="h2"
                fontWeight="fontWeightBold"
                sx={{
                  mb: 1
                }}
              >
                {props.jobPost.singleJob.data.name}
              </Typography>
              <Typography variant="h3" fontWeight="fontWeightBold">
                {props.jobPost.singleJob.data.jobType === 'intern' ? <>Intern</> : <>Full Time</>}
                ,
                {props.jobPost.singleJob.data.company.name}
              </Typography>
              {/* <Typography variant="h5" fontWeight="fontWeightBold">
              {props.jobPost.singleJob.data.jobType === "intern" ? <>Intern</>:<>Full Time</>}
            </Typography> */}
            </Box>
            <Box>
              {props.jobPost.singleJob.applied ? (
                <Button disabled variant="contained">
                  Applied
                </Button>
              ) : (
                <Button onClick={openApplyDialog} variant="contained">
                  Apply Now
                </Button>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 3,
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{
              display: 'flex'
            }}
            >
              <LocationOnIcon />
              {' '}
              <Typography>{props.jobPost.singleJob.data.location}</Typography>
            </Box>

            <Box>
              <RouterLink to={`/app/jobs/${props.jobPost.singleJob.data._id}/progress`}>
                <Button variant="contained" size="small">
                  See Progress
                </Button>
              </RouterLink>
              {/* <Button variant="contained" size="small">
              See Progres
            </Button> */}
            </Box>
          </Box>
          <Box sx={{
            mb: 1
          }}
          >
            <Typography
              variant="h4"
              sx={{
                mb: 3
              }}
            >
              {props.jobPost.singleJob.data.drive}
            </Typography>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}
            >
              <Box>
                <Typography>
                  Form Link :
                  <a href={props.jobPost.singleJob.data.formLink} target="_blank">{props.jobPost.singleJob.data.formLink}</a>
                </Typography>
                {/* <Typography>Form Link : <RouterLink to={{pathname:`${props.jobPost.singleJob.data.formLink}`}} target="_blank">{props.jobPost.singleJob.data.formLink}</RouterLink></Typography> */}
                <Typography sx={{
                  mb: 0
                }}
                >
                  {' '}
                  Handled By :
                  {' '}
                  {props.jobPost.singleJob.data.handledBy ? (
                    <a href={`mailto:${props.jobPost.singleJob.data.handledBy.email}`} target="_blank">
                      {props.jobPost.singleJob.data.handledBy.email}
                    </a>
                  ) : (
                    <a href="" target="_blank" />
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography sx={{
                  fontWeight: '600'
                }}
                >
                  Applications Open :
                  {' '}
                  {`${new Date(props.jobPost.singleJob.data.applicationOpen).getDate()
                  }-${
                    new Date(props.jobPost.singleJob.data.applicationOpen).getMonth() + 1
                  }-${
                    new Date(props.jobPost.singleJob.data.applicationOpen).getFullYear()
                  } ${
                    new Date(props.jobPost.singleJob.data.applicationOpen).getHours() >= 10 ? new Date(props.jobPost.singleJob.data.applicationOpen).getHours() : `0${new Date(props.jobPost.singleJob.data.applicationOpen).getHours()}`

                  }:${
                    new Date(props.jobPost.singleJob.data.applicationOpen).getMinutes() >= 10 ? new Date(props.jobPost.singleJob.data.applicationOpen).getMinutes() : `0${new Date(props.jobPost.singleJob.data.applicationOpen).getMinutes()}`}`}
                  {' '}
                </Typography>
                <Typography sx={{
                  fontWeight: '600'
                }}
                >
                  Applications Closed :
                  {' '}
                  {`${new Date(props.jobPost.singleJob.data.applicationClosed).getDate()
                  }-${
                    new Date(props.jobPost.singleJob.data.applicationClosed).getMonth() + 1
                  }-${
                    new Date(props.jobPost.singleJob.data.applicationClosed).getFullYear()
                  } ${
                    new Date(props.jobPost.singleJob.data.applicationClosed).getHours() >= 10 ? new Date(props.jobPost.singleJob.data.applicationClosed).getHours() : `0${new Date(props.jobPost.singleJob.data.applicationClosed).getHours()}`

                  }:${
                    new Date(props.jobPost.singleJob.data.applicationClosed).getMinutes() >= 10 ? new Date(props.jobPost.singleJob.data.applicationClosed).getMinutes() : `0${new Date(props.jobPost.singleJob.data.applicationClosed).getMinutes()}`}`}
                  {' '}
                </Typography>
              </Box>
            </Box>

          </Box>
          <Box
            sx={{
              mb: 2
            }}
          >

            <Typography sx={{
              mb: 2,
              mt: 2
            }}
            >
              <Typography display="inline" fontWeight={600}>Post : </Typography>
              {parse(props.jobPost.singleJob.data.postData)}
            </Typography>
            <Typography sx={{
              mb: 2
            }}
            >
              <Typography display="inline" fontWeight={600}>Job Description : </Typography>
              <a href={props.jobPost.singleJob.data.jobDescription} target="_blank">{props.jobPost.singleJob.data.jobDescription}</a>
            </Typography>
            {/* <Typography > Handled By : props.jobPost.singleJob.data.Handled_by</Typography> */}
            {/* <Typography > Coordinated By : props.jobPost.singleJob.data.coordinator_name</Typography> */}
            <Typography sx={{
              fontWeight: '600'
            }}
            >
              {props.jobPost.singleJob.data.pwdOnly ? <>Only for PWD students</> : <></>}
            </Typography>
            <Typography sx={{
              fontWeight: '600'
            }}
            >
              {props.jobPost.singleJob.data.jobType === 'intern' ? (
                <Typography>
                  {props.jobPost.singleJob.data.openForPlaced ? (
                    <>
                      Open for Placed Students with Stipend/CTC Less than :
                      {props.jobPost.singleJob.data.ctcCutoff}
                      {' '}
                      per month/ LPA respectively
                    </>
                  ) : <></>}
                </Typography>
              ) : (
                <Typography>
                  {props.jobPost.singleJob.data.openForPlaced ? (
                    <>
                      Open for Placed Students with Stipend/CTC Less than :
                      {props.jobPost.singleJob.data.ctcCutoff}
                      {' '}
                      per month/ LPA respectively
                    </>
                  ) : <></>}
                </Typography>
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              mb: 3,
              alignItems: 'center'
            }}
          >
            <Box>
              {props.jobPost.singleJob.data.jobType === 'intern' ? (
                <Typography>
                  {' '}
                  <Typography display="inline" fontWeight={600}>Stipend</Typography>
                  {' '}
                  :
                  {' '}
                  {props.jobPost.singleJob.data.ctc}
                  {' '}
                  per Month
                </Typography>
              ) : (
                <Typography>
                  <Typography display="inline" fontWeight={600}>CTC</Typography>
                  {' '}
                  :
                  {' '}
                  {props.jobPost.singleJob.data.ctc}
                  {' '}
                  LPA
                </Typography>
              )}
            </Box>

          </Box>
          <Box sx={{
            mb: 2
          }}
          >
            <Typography>
              12th Class Cutoff :
              {props.jobPost.singleJob.data.twelfthPercentageCutoff}
              {' '}
              %
            </Typography>
            <Typography>
              10th Class Cutoff :
              {props.jobPost.singleJob.data.tenthPercentageCutoff}
              {' '}
              %
            </Typography>
            {props.jobPost.singleJob.data.undergraduatePercentageCutoff
              ? (
                <>
                  <Typography>
                    Undergraduate Cutoff :
                    {props.jobPost.singleJob.data.undergraduatePercentageCutoff}
                    {' '}
                    %
                  </Typography>
                </>
              )
              : null}
            <Typography>
              Number of Backlogs Allowed :
              {props.jobPost.singleJob.data.backlogsAllowed}
            </Typography>

          </Box>
          <EligibleTable jobPost={props.jobPost.singleJob.data} />
          {/* <Box sx={{
                      p:4
                  }}>
                      <Box sx={{
                          display:'flex',
                          justifyContent:'space-between'
                      }}>
                          <Typography variant="h4">Program Eligible</Typography>
                          <Typography variant="h4">Cutoff</Typography>
                          <Typography variant="h4">Branches</Typography>
                      </Box>
                  </Box> */}
        </Box>
      ) : (
        <>
          {/* <Box sx={{
        p:4
      }}>
        <Button
          sx={{
            mb: 4
          }}
          color="primary"
          variant="contained"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Box> */}
          <NotFound />
        </>
      )
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(JobDetails);
