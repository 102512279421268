import JOBPOST from '../JsonData/jobPost.json';
import * as ActionTypes from './ActionTypes';

export const JobPost = (state = {
    isLoading:false,
    errmess:null,
    jobs:{},
    progressPost:{},
    progressJobId:"",
    jobPage:1,
    singleJob:{}
}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_JOBS:
            return{...state, jobs:action.payload}

        case ActionTypes.JOBS_LOADING:
            return{...state, isLoading:action.payload}

        case ActionTypes.JOBS_FAILED:
            return{...state,  errmess:action.payload}

        case ActionTypes.ADD_PROGRESS:
            return{...state,  progressPost:action.payload}

        case ActionTypes.RESET_PROGRESS:
            return{...state,  progressPost:{}}

        case ActionTypes.SET_PROGRESSJOB:
            return{...state,  progressJobId:action.payload}

        case ActionTypes.SET_JOBPAGE:
            return{...state,  jobPage:action.payload}

        case ActionTypes.GET_SINGLEJOB:
            return{...state,  singleJob:action.payload}

        case ActionTypes.RESET_SINGLEJOB:
            return{...state,  singleJob:{}}
            
        default:
          return state;
      }
};