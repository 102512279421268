import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid, Button } from '@material-ui/core';
// import Typography from '@material-ui/core/Typography'
import NotificationCard from '../pageComponents/dashboard/NotificationCard';
import WebinarPostCard from '../pageComponents/dashboard/WebinarPostCard';
import Pagination from '@material-ui/core/Pagination';
import { connect } from 'react-redux';
import { fetchNotifications,fetchWebinars,changeDashNotifDisplay } from '../redux/ActionCreators';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    student: state.student,
    branches: state.branches,
    webinar_data: state.webinar_data,
    jobPost: state.jobPost,
    loadingBar: state.loadingBar,
    notif: state.notif,
    applications: state.applications,
    notifications: state.notifications
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchNotifications: (notifPage) => dispatch(fetchNotifications(notifPage)),
  fetchWebinars: (page) => dispatch(fetchWebinars(page)),
  changeDashNotifDisplay : (dat) => dispatch(changeDashNotifDisplay(dat))

  // fetchStudent: () => dispatch(fetchStudent())
});

function Dashboard(props) {
  const [option, setOption] = useState(true);
  const [notifPage,setNotifPage] = useState(props.notifications.notificationPage);

  const handleNotifPageChange = (event,value) => {
    if(value !== props.notifications.notificationPage)
    {
      // console.log(value);
      setNotifPage(value);
      props.fetchNotifications(value);
      props.changeDashNotifDisplay(true);
      // console.log(notifPage)
    }
}

const handleChangeNotPrev = (e) => {
  e.preventDefault();
  setNotifPage(props.notifications.notificationPage - 1);
  props.fetchNotifications(props.notifications.notificationPage - 1);
  props.changeDashNotifDisplay(true);
}

const handleChangeNotNext = (e) => {
  e.preventDefault();
  setNotifPage(props.notifications.notificationPage + 1);
  props.fetchNotifications(props.notifications.notificationPage + 1);
  props.changeDashNotifDisplay(true);
}

const handleWebinarPageChange = (event,value) => {
  if(value !== props.webinar_data.webinarPage)
  {
    // console.log(value);
    setNotifPage(value);
    props.fetchWebinars(value);
    props.changeDashNotifDisplay(false);
    setOption(false)
    // console.log(notifPage)
  }
}

const handleChangeWebNext = (e) => {
  e.preventDefault();
  setNotifPage(props.webinar_data.webinarPage + 1);
    props.fetchWebinars(props.webinar_data.webinarPage + 1);
    props.changeDashNotifDisplay(false);
    setOption(false)
}

const handleChangeWebPrev = (e) => {
  e.preventDefault();
  setNotifPage(props.webinar_data.webinarPage - 1);
    props.fetchWebinars(props.webinar_data.webinarPage - 1);
    props.changeDashNotifDisplay(false);
    setOption(false)
}

const handleChangeOptNot = (e) => {
  setOption(!option);
  props.changeDashNotifDisplay(true);
};


  const handleChangeOptWeb = (e) => {
    setOption(!option);
    props.changeDashNotifDisplay(false);
  };
  if (
    props.loadingBar.toShow ||
    props.studentisLoading ||
    props.jobPost.isLoading ||
    props.webinar_data.isLoading ||
    props.notifications.isLoading
  ) {
    return (
      <>
        <Helmet>
          <title>Dashboard | DTU RM</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            <Grid container spacing={3}>
              <Box
                sx={{
                  p: 2,
                  width: '100%'
                }}
              >
                {props.notifications.dashDisplay === true ? (
                  <>
                    <Box
                      sx={{
                        display: 'flex'
                      }}
                    >
                      <Button color="primary" variant="contained">
                        Notifications
                      </Button>
                      <Button sx={{ mx: 1 }} onClick={handleChangeOptWeb}>
                        Events
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}
                    ></Box>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: 'flex'
                      }}
                    >
                      <Button onClick={handleChangeOptNot}>Notifications</Button>
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ mx: 1 }}
                      >
                        Webinars
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}
                    ></Box>
                  </>
                )}
              </Box>
            </Grid>
          </Container>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>Dashboard | DTU RM</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            <Grid container spacing={3}>
              <Box
                sx={{
                  p: 2,
                  width: '100%'
                }}
              >
                {props.notifications.dashDisplay === true ? (
                  <>
                    <Box
                      sx={{
                        display: 'flex'
                      }}
                    >
                      <Button color="primary" variant="contained">
                      Notifications
                      </Button>
                      <Button sx={{ mx: 1 }} onClick={handleChangeOptWeb}>
                        Events
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}
                    >
                      {/* <JobPostCard
                        jobs={props.jobPost.jobs.data}
                        count={true}
                      /> */}
                      <NotificationCard />
                    </Box>
                    <Box sx={{
                        display:"flex",
                        justifyContent:'space-evenly',
                        alignItems:'center',
                        width:'50%',
                        margin:'auto'
                    }}>
                      {/* {console.log(props.notifications.notificationPage)} */}
                        {props.notifications.dat.data ? <>
                        {props.notifications.notificationPage > 1 ? <ArrowBackIcon sx={{
                          cursor:'pointer'
                        }} onClick={handleChangeNotPrev}/> : <ArrowBackIcon sx={{
                          cursor:'pointer'
                        }} color='disabled'/>}
                        <Pagination page={props.notifications.notificationPage} count={props.notifications.notificationPage} onChange={handleNotifPageChange} color="primary" size="large"  hidePrevButton hideNextButton/> 
                        {props.notifications.dat.data.length == 10 ? <ArrowForwardIcon sx={{
                          cursor:'pointer'
                        }} onClick={handleChangeNotNext} /> : <ArrowForwardIcon sx={{
                          cursor:'pointer'
                        }} color='disabled' />} </> : <></>}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: 'flex'
                      }}
                    >
                      <Button onClick={handleChangeOptNot}>Notifications</Button>
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ mx: 1 }}
                      >
                        Events
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}
                    >
                      <WebinarPostCard />
                    </Box>
                    <Box sx={{
                        display:"flex",
                        justifyContent:'space-evenly',
                        alignItems:'center',
                        width:'50%',
                        margin:'auto'
                    }}>
                        {props.webinar_data.webinars.data ? <>
                          {props.webinar_data.webinarPage > 1 ? <ArrowBackIcon sx={{
                            cursor:'pointer'
                          }} onClick={handleChangeWebPrev}/> : <ArrowBackIcon sx={{
                            cursor:'pointer'
                          }} color='disabled'/>}
                          {console.log(props.webinar_data)}
                        <Pagination page={props.webinar_data.webinarPage} count={props.webinar_data.webinarPage} onChange={handleWebinarPageChange}  color="primary" size="large"  hidePrevButton hideNextButton/> 
                        {props.webinar_data.webinars.data.length == 10 ? <ArrowForwardIcon sx={{
                            cursor:'pointer'
                          }} onClick={handleChangeWebNext} /> : <ArrowForwardIcon sx={{
                            cursor:'pointer'
                          }} color='disabled' />}
                        </> : <></>}
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Container>
        </Box>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
