import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import jsonJobPosts from '../../JsonData/jobPost.json';
import Grid from '@material-ui/core/Grid'
import WebinarDat from '../../JsonData/webinar_data.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
// import './jobPostCard.css';
import { connect } from 'react-redux';
import parse from 'html-react-parser';

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    student: state.student,
    branches: state.branches,
    webinar_data: state.webinar_data,
    jobPost: state.jobPost,
    loadingBar: state.loadingBar,
    notif: state.notif,
    applications: state.applications,
    notifications: state.notifications
  };
};

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: 12
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold'
  },
  pos: {
    marginBottom: 12
  }
});

function WebinarPostCard(props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  const [webinarDat, setWebinarDat] = useState();

  useEffect(() => {
    if (props.webinar_data.webinars.data) {
      setWebinarDat(props.webinar_data.webinars.data);
    }
  }, [props.webinar_data.webinars]);

  if (webinarDat) {
    
    return (
      <Grid container justify="center" alignItems="center" spacing={2}>
      {webinarDat.map((webinarSingle, index) => {
      return (
        <Grid item xs={12} sm={12}>
        <Box>
          <Card className={classes.root}>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  mb: 2
                }}
              >
                <Avatar aria-label="recipe">
                {webinarSingle.company.name.charAt(0).toUpperCase()}
                </Avatar>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pl: 2
                  }}
                >
                  <Typography variant="h5" fontWeight={600}>
                    {webinarSingle.company.name}
                  </Typography>
                  {/* <Typography>{webinarSingle.company}</Typography> */}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column'
                }}
              >
                {/* <Typography>Date : {webinarSingle.date}</Typography> */}
                <Typography>{parse(webinarSingle.postData)}</Typography>
                <Typography>
                  {' '}
                  Click{' '}
                  <Link
                    target="_blank"
                    color="secondary"
                    href={webinarSingle.link}
                  >
                    here
                  </Link>{' '}
                  to Know More
                </Typography>
                {/* <Typography> : {webinarSingle.venue}</Typography> */}
                {/* <Typography>Know More at: </Typography> */}
                {/* <Typography color="blue" target="blank" href={webinarSingle.miscPost.link}>{webinarSingle.miscPost.link}</Typography> */}
              </Box>
              {/* <Box sx={{
                        display:'flex',
                        justifyContent:'space-between'
                      }}>
                        <Typography>CutOff : Btech: 7.5, Mtech: 8.5</Typography>
                        <Typography>CTC : 15 Lakhs per annum</Typography>
                      </Box> */}
            </CardContent>
          </Card>
        </Box>
        </Grid>
      );
    })}
    </Grid>
    )
    } else {
    return <></>;
  }
}

export default connect(mapStateToProps)(WebinarPostCard);
