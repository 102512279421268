import * as ActionTypes from './ActionTypes';

export const LoadingBar = (state = {
    toShow:false
},action) => {
    switch (action.type) {
        case ActionTypes.LOADING_BAR : 
            return {...state, toShow:action.payload}
        
        default:
            return {...state}
    }
}