import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  List,
  Typography
} from '@material-ui/core';
import {
  User as UserIcon,
  FileText as fileTextIcon,
  Archive as ArchiveIcon,
  Briefcase as briefcaseIcon,
  Inbox as inboxIcon,
  Folder as folderIcon
} from 'react-feather';
import NavItem from './NavItem';
import { LogoutUser } from '../redux/ActionCreators';
import { cloudFrontUrl } from '../baseUrl';
import { withStyles, makeStyles } from '@material-ui/styles';
import { red} from '@material-ui/core/colors';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core';
import './LogoutBtn.css'

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    student: state.student,
    branches: state.branches,
    webinar_data: state.webinar_data,
    jobPost: state.jobPost,
    loadingBar: state.loadingBar,
    notif: state.notif,
    applications: state.applications,
    notifications: state.notifications
  };
};

const mapDispatchToProps = (dispatch) => ({
  LogoutUser: () => dispatch(LogoutUser())
  // fetchStudent: () => dispatch(fetchStudent())
});

// const user = {
//   avatar: jsonUser.photo,
//   Roll_no: jsonUser.roll_no,
//   name: jsonUser.firstname + ' ' + jsonUser.lastname
// };

const items = [
  {
    href: '/app/dashboard',
    icon: inboxIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/jobs',
    icon: briefcaseIcon,
    title: 'Jobs'
  },
  {
    href: '/app/myApplications',
    icon: ArchiveIcon,
    title: 'My Applications'
  },
  {
    href: '/app/myInfo',
    icon: folderIcon,
    title: 'My Information'
  },
  {
    href: '/app/myResume',
    icon: fileTextIcon,
    title: 'My Resume'
  },
  {
    href: '/app/myAccount',
    icon: UserIcon,
    title: 'My Account'
  }
];

const theme1 = createTheme({
  palette: {
    primary: red,
  },
});

const DashboardSidebar = ({
  onMobileClose,
  openMobile,
  LogoutUser,
  loadingBar,
  student,
  jobPost,
  webinar_data
}) => {
  const location = useLocation();
  const user = student.student.data;
  // console.log(user);
  const handleSubmit = (e) => {
    e.preventDefault();
    LogoutUser();
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const isLoading = loadingBar.toShow || jobPost.isLoading || student.isLoading || webinar_data.isLoading;
  const sidebarContent = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '98%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
          py: 1
        }}
      >
        <Avatar
          component={RouterLink}
          src={(user) ? (encodeURI(cloudFrontUrl + user.photo)) : ''}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64,
            mb:1
          }}
          to="/app/myInfo"
        />
        <Typography color="textPrimary" variant="h5">
          {(user) ? (user.firstName + ' ' + user.lastName) : 'user_name'}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {(user) ? user.rollNo : 'user_rollNo'}
        </Typography>
      </Box>
      {/* <Divider /> */}
      <Box sx={{ p: 2, paddingTop:0, paddingBottom:0 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
        <Box className="logoutBtn">
          <Button
            variant="contained"
            size="small"
            onClick={handleSubmit}
          >
            Logout
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.default',
          m: 2,
          p: 2,
          mt:0
        }}
      >
        <Typography
          align="center"
          gutterBottom
          variant="h4"
        >
          Need help?
        </Typography>
        {/* <Typography
          align="center"
          variant="body2"
        >
          Upgrade to PRO version and access 20 more screens
        </Typography> */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 1
          }}
        >
          <Button
            color="primary"
            component="a"
            href="mailto:support-rm@dtu.ac.in"
            variant="contained"
          >
            Contact Team
          </Button>
        </Box>
      </Box>
      {/* <Box sx={{
        display: 'flex',
        justifyContent: 'center',
      }}>
        <RouterLink to="maito:support-rm@dtu.ac.in">
        <ThemeProvider theme={theme1}>
          <Button sx={{
            backgroundColor:red
          }} color="primary" variant="contained">
            Support
          </Button>
        </ThemeProvider>
        </RouterLink>
      </Box> */}
      
    </Box>
  );

  return (
    <>
      <Box sx={{ display: { lg: 'none', xs: 'block' } }}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {sidebarContent()}
        </Drawer>
      </Box>
      <Box sx={{ display: { lg: 'block', xs: 'none' } }}>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 68,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {sidebarContent()}
        </Drawer>
      </Box>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSidebar);
