import React from 'react';
import { withStyles, makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import BranchesElig from './BranchesElig';
import './EligibleTable.css';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'black',
    color: 'white',
    // fontSize: '1.1rem',
  },
  body: {
    fontSize: '1.1rem',
    // border: '2px solid red'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.action.hover,
      // border: '2px solid red'
    }
  },
}))(TableRow);

const StyledTableRowNotElig = withStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.action.hover,
    border: '2px solid red'
  },
}))(TableRow);

function createData(name, elig, cutoff, branches) {
  return {
    name, elig, cutoff, branches
  };
}

const useStyles = makeStyles({
  table: {
    minWidth: 100,
  },
});

export default function EligibleTable(props) {
  const rows = [
    createData('BTech', props.jobPost.btech, props.jobPost.btechCutoff, props.jobPost.btechBranches),
    createData('MTech', props.jobPost.mtech, props.jobPost.mtechCutoff, props.jobPost.mtechBranches),
    createData('MSc', props.jobPost.msc, props.jobPost.mscCutoff, props.jobPost.mscBranches),
    createData('MBA', props.jobPost.mba, props.jobPost.mbaCutoff, []),
    createData('BBA', props.jobPost.bba, props.jobPost.bbaCutoff, []),
    createData('BA(Eco)', props.jobPost.ba, props.jobPost.baCutoff, []),
    createData('MA(Eco)', props.jobPost.ma, props.jobPost.maCutoff, []),
    createData('BDes', props.jobPost.bdes, props.jobPost.bdesCutoff, []),
    createData('MDes', props.jobPost.mdes, props.jobPost.mdesCutoff, [])
  ];
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              className="tableCell"
              sx={{
                color: 'white'
              }}
            >
              Programs Eligible

            </StyledTableCell>
            <StyledTableCell
              className="tableCell"
              sx={{
                color: 'white'
              }}
              align="left"
            >
              Cutoff

            </StyledTableCell>
            <StyledTableCell
              className="tableCell"
              sx={{
                color: 'white'
              }}
              align="center"
            >
              Branches

            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            if (row.elig) {
              if (row.name === 'BTech' || row.name === 'MTech' || row.name === 'MSc') {
                // console.log(row.cutoff + " " + row.name)
                return (
                  row.cutoff != 11
                    ? (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell className="tableCell" component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell className="tableCell" align="left">{row.cutoff}</StyledTableCell>
                        <StyledTableCell className="tableCell" align="center">
                          <BranchesElig course={row.name} branches={row.branches} />
                          {' '}
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                    : (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell className="tableCell" component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell className="tableCell" align="left" />
                        <StyledTableCell className="tableCell" align="center">
                          <BranchesElig course={row.name} branches={row.branches} />
                          {' '}
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                );
              }

              return (
                row.cutoff != 11
                  ? (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell className="tableCell" component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell className="tableCell" align="left">{row.cutoff}</StyledTableCell>
                      <StyledTableCell className="tableCell" align="center">
                        {' '}
                        <Typography>Eligible</Typography>
                        {' '}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                  : (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell className="tableCell" component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell className="tableCell" align="left" />
                      <StyledTableCell className="tableCell" align="center">
                        {' '}
                        <Typography>Eligible</Typography>
                        {' '}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
              );
            }

            return (
              row.cutoff != 11
                ? (
                  <StyledTableRowNotElig
                    sx={{
                      color: 'gray'
                    }}
                    key={row.name}
                  >
                    <StyledTableCell className="tableCell" component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell className="tableCell" align="left">{row.cutoff}</StyledTableCell>
                    <StyledTableCell className="tableCell" align="center">
                      {' '}
                      <Typography>Not Eligible</Typography>
                      {' '}
                    </StyledTableCell>
                  </StyledTableRowNotElig>
                )
                : (
                  <StyledTableRowNotElig
                    sx={{
                      color: 'gray'
                    }}
                    key={row.name}
                  >
                    <StyledTableCell className="tableCell" component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell className="tableCell" align="left" />
                    <StyledTableCell className="tableCell" align="center">
                      {' '}
                      <Typography>Not Eligible</Typography>
                      {' '}
                    </StyledTableCell>
                  </StyledTableRowNotElig>
                )
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
