import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { cloudFrontUrl } from '../baseUrl';
import {
  updateProfilePic,
  updatePassword,
  updateSecurityQuestions
} from '../redux/ActionCreators';
import Avatar from '@material-ui/core/Avatar';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    student: state.student,
    branches: state.branches,
    webinar_data: state.webinar_data,
    jobPost: state.jobPost,
    loadingBar: state.loadingBar,
    notif: state.notif,
    applications: state.applications,
    notifications: state.notifications
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateProfilePic: (profilePic) => dispatch(updateProfilePic(profilePic)),
  updatePassword: (prevPass, newPass) =>
    dispatch(updatePassword(prevPass, newPass))

  // fetchStudent: () => dispatch(fetchStudent())
});

function MyAccount(props) {
  const [prevPass, setPrevPass] = useState('');
  const [rePrevPass, setRePrevPass] = useState('');
  const [newPass, setNewPass] = useState('');

  const errorHelper =
    'Password must have minimum length of 8 and contain atleast an uppercase character,a lowercase character, a special character and a number';

  const pattern = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$'
  );

  const [showPassword1, setShowPassword1] = useState(false);

  const handleClickShowPassword1 = (e) => {
    e.preventDefault();
    setShowPassword1(!showPassword1);
  };

  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword2 = (e) => {
    e.preventDefault();
    setShowPassword2(!showPassword2);
  };

  const [showPassword3, setShowPassword3] = useState(false);

  const handleClickShowPassword3 = (e) => {
    e.preventDefault();
    setShowPassword3(!showPassword3);
  };

  const [profilePic, setProfilePic] = useState();
  const handleChangePrev1 = (e) => {
    setPrevPass(e.target.value);
  };

  const submitProfilePic = (e) => {
    e.preventDefault();

    props.updateProfilePic(profilePic);
  };

  const handleProfilePic = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const temp = new FormData();
    temp.append('image', file);

    setProfilePic(temp);

    // props.updateProfilePic(profilePic);
  };

  const handleChangePrev2 = (e) => {
    setRePrevPass(e.target.value);
  };
  const handleChangeNew = (e) => {
    setNewPass(e.target.value);
  };

  const handleSubmitPass = (e) => {
    e.preventDefault();
    props.updatePassword(prevPass, newPass);
  };

  if (!props.student.student.data) {
    return (
      <>
        <Box
          sx={{
            p: 2,
            mt: 3
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar
              // component={RouterLink}
              // src={encodeURI("https://d363qrngffnfxd.cloudfront.net/" + props.student.student.data.photo)}
              sx={{
                width: 200,
                height: 200
              }}
            />
            {/* <img style={{
                        width:'10rem',
                        height:'10rem'
                    }} src='https://upload.wikimedia.org/wikipedia/en/thumb/b/b5/DTU%2C_Delhi_official_logo.png/200px-DTU%2C_Delhi_official_logo.png'></img> */}
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box
          sx={{
            p: 2,
            mt: 3
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {/* <img style={{
                        width:'10rem',
                        height:'10rem'
                    }} src={encodeURI("https://d363qrngffnfxd.cloudfront.net/" + props.student.student.data.photo)}></img> */}
            <Avatar
              // component={RouterLink}
              src={encodeURI(
                cloudFrontUrl +
                  props.student.student.data.photo
              )}
              sx={{
                width: 200,
                height: 200
              }}
            />
            <Box
              sx={{
                alignSelf: 'flex-start',
                mt: 3,
                mb: 3,
                width: '100%'
              }}
            >
              <Typography variant="h3">Change Your Profile Picture</Typography>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
                sx={{
                  mt: 2
                }}
              >
                <Grid className="multiLineText" item sm={3} md={3}>
                  <Typography varient="h4">
                    Upload Your New Profile Picture (&lt;2mb)
                  </Typography>
                </Grid>
                <Grid className="multiLineText" item sm={6} md={3}>
                  <Button
                    variant="outlined"
                    component="label"
                    color="secondary"
                  >
                    Click to Upload New
                    <input
                      onChange={handleProfilePic}
                      type="file"
                      accept=".jpeg,.jpg,.png"
                      hidden
                    />
                  </Button>
                </Grid>
                {profilePic ? (
                  <Grid className="multiLineText" item sm={6} md={2}>
                    <Button
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                      onClick={submitProfilePic}
                    >
                      Save
                    </Button>
                  </Grid>
                ) : (
                  <></>
                )}
                {profilePic ? (
                  <Typography>{profilePic.get('image').name}</Typography>
                ) : (
                  <></>
                )}
              </Grid>
            </Box>
            <Box
              sx={{
                alignSelf: 'flex-start',
                mt: 3
              }}
            >
              <Typography variant="h3">Change Your Password</Typography>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
                sx={{
                  mt: 2
                }}
              >
                <Grid className="multiLineText" item sm={4} md={3}>
                  <Typography varient="h4">
                    Enter Your Current Password
                  </Typography>
                </Grid>
                <Grid className="multiLineText" item sm={8} md={8}>
                  <TextField
                    type={showPassword1 ? 'text' : 'password'}
                    value={prevPass}
                    onChange={handleChangePrev1}
                    size="small"
                    fullWidth="true"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={handleClickShowPassword1}
                            // onMouseDown={this.handleMouseDownPassword}
                          >
                            {/* <VisibilityOffIcon /> */}
                            {showPassword1 ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  ></TextField>
                </Grid>
                <Grid className="multiLineText" item sm={4} md={3}>
                  <Typography varient="h4">Enter Your New Password</Typography>
                </Grid>
                <Grid className="multiLineText" item sm={8} md={8}>
                  <TextField
                    error={
                      pattern.test(rePrevPass) && rePrevPass.length >= 8
                        ? false
                        : true
                    }
                    // error='false'
                    helperText={
                      pattern.test(rePrevPass) && rePrevPass.length >= 8
                        ? ''
                        : errorHelper
                    }
                    // helperText=''
                    // type="password"
                    type={showPassword2 ? 'text' : 'password'}
                    value={rePrevPass}
                    onChange={handleChangePrev2}
                    size="small"
                    fullWidth="true"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={handleClickShowPassword2}
                            // onMouseDown={this.handleMouseDownPassword}
                          >
                            {/* <VisibilityOffIcon /> */}
                            {showPassword2 ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  ></TextField>
                </Grid>
                <Grid className="multiLineText" item sm={4} md={3}>
                  <Typography varient="h4">
                    Re-enter Your New Password
                  </Typography>
                </Grid>
                <Grid className="multiLineText" item sm={8} md={8}>
                  <TextField
                    type="password"
                    error={pattern.test(newPass) ? false : true}
                    // error='false'
                    type={showPassword3 ? 'text' : 'password'}
                    helperText={pattern.test(newPass) ? '' : errorHelper}
                    value={newPass}
                    onChange={handleChangeNew}
                    size="small"
                    fullWidth="true"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={handleClickShowPassword3}
                            // onMouseDown={this.handleMouseDownPassword}
                          >
                            {/* <VisibilityOffIcon /> */}
                            {showPassword3 ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  ></TextField>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 3
                }}
              >
                {newPass === rePrevPass && prevPass !== '' && newPass !== '' ? (
                  <Button onClick={handleSubmitPass} variant="contained">
                    Submit
                  </Button>
                ) : (
                  <Button disabled variant="contained">
                    Submit
                  </Button>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 3
                }}
              >
                {newPass === rePrevPass && prevPass !== '' && newPass !== '' ? (
                  <></>
                ) : (
                  <Typography color="error">Passwords don't match</Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
